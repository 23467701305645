import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppOptionsService, ErrorsHandlingService } from '../../../core/services';

@Injectable({
  providedIn: 'root'
})
export class SuggestFeatureService {
  private apiUrl = '';

  constructor(
    private http: HttpClient,
    private options: AppOptionsService,
    private errorsHandlingService: ErrorsHandlingService,
  ) {
    this.apiUrl = this.options.getApiUrl();
  }

  public sendSuggestFeature(suggest: {
    title: string | Blob;
    description: string | Blob;
    filesList: File[];
  }): Observable<{ status: string, message: string }> {
    const formData: FormData = new FormData();
    formData.append('title', suggest.title);
    formData.append('description', suggest.description);
    if (suggest.filesList && suggest.filesList.length > 0) {
      suggest.filesList.forEach((file, id) => {
        if (!(file instanceof File)) {
          file = new File([file as File], (file as File).name, { type: (file as File).type, lastModified: (file as File).lastModified });
        }
        formData.append(`file-${id}`, file);
      });
    }

    return this.http.post<{ status: string, message: string }>(`${this.apiUrl}/suggest-feature`, formData)
      .pipe(catchError(this.errorsHandlingService.processError));
  }

  public get getImagesUrl(): string {
    return `${this.apiUrl}/suggest-feature/images`;
  }
}
