<div class="platform-form platform-form__lists">
  <div class="platform-form__top" *nzModalTitle>
    <h3 class="title">Notification</h3>
  </div>
  <div class="platform-form__content">
    <p class="title-text">
      Dear partner, please take into consideration that
      <strong>JS tags have a standard logic:</strong>
      the impression counts at the very moment the JS tag starts firing, NOT at
      the moment the creative is displayed.
      <br /><br />
      Please note that there might be a case when your demand partner will not
      count the impression because the demand partner doesn’t see a display
      event while SSP fired a JS tag and the impression was counted.
      {{ title }} <br /><br />

      SmartHub is not involved in the business relations between you and your
      partners, it is a tech solution not liable for any commercial disputes.
    </p>
  </div>
  <div *nzModalFooter id="modalWindowJs" class="button-footer">
    <button
      type="submit"
      nz-button
      nzType="primary"
      id="confirmButton"
      (click)="onAccept()"
    >
      Accept
    </button>
    <button
      type="button"
      nz-button
      nzType="default"
      id="closeButton"
      (click)="onCancel()"
    >
      Close
    </button>
  </div>
</div>
