<div class="stat-header">
  <nz-tabset (nzSelectedIndexChange)="onChangeTab($event)" nzCentered>
    <nz-tab *ngFor="let tab of tabItems" [nzTitle]="titleTemplate">
      <ng-template #titleTemplate>
        <div class="tab-item" [class.active]="tabIndex === tab.id">
          {{ tab.name }}
        </div>
      </ng-template>
    </nz-tab>
  </nz-tabset>
  <div class="traffic-dashboard" *ngIf="tabIndex === 0">
    <div class="traffic-body" *ngFor="let profit of profitItems">
      <div class="traffic-content">
        <p class="traffic-value">$ {{ profit.value | number : "1.2-2" }}</p>
        <h4 class="traffic-name">{{ profit.name }}</h4>
      </div>
    </div>
  </div>
  <div class="traffic-dashboard" *ngIf="tabIndex === 1">
    <div class="traffic-body" *ngFor="let sspSpend of sspSpendItems">
      <div class="traffic-content">
        <p class="traffic-value">$ {{ sspSpend.value | number : "1.2-2" }}</p>
        <h4 class="traffic-name">{{ sspSpend.name }}</h4>
      </div>
    </div>
  </div>
  <div class="traffic-dashboard" *ngIf="tabIndex === 2">
    <div class="traffic-body" *ngFor="let dspSpend of dspSpendItems">
      <div class="traffic-content">
        <p class="traffic-value">$ {{ dspSpend.value | number : "1.2-2" }}</p>
        <h4 class="traffic-name">{{ dspSpend.name }}</h4>
      </div>
    </div>
  </div>
</div>
