import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NzModalService } from 'ng-zorro-antd/modal';

import { NotificationComponent } from '@shared/components';

import { throwError as _throw } from 'rxjs';

import { AuthService } from './../auth';
import { NotificationService } from '../notification';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorsHandlingService {

  constructor(
    private authService: AuthService,
    private modalService: NzModalService,
    private router: Router,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
  ) { }

  public handleError(error: { code: number, messages: string[] }): boolean {
    if (error.code === 0) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }

    if (error.code === 400) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }
    if (error.code === 403) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }
    if (error.code === 401) {
      this.handleUnauthorizedError(error);
      return false;
    }

    if (error.code === 404) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null);
      if (error.messages.includes('Partner not found')) this.router.navigate(['404']);
      return false;
    }

    if (error.code === 422) {
      let width = null;
      error.messages.forEach((message) => {
        message.length > 40 ? width = '450px' : null;
      });
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), width);
      return false;
    }

    if (error.code === 500) {
      this.showNotification('error', 'error', 'error', error.messages.join('\r\n'), null);
      return false;
    }

    return true;
  }

  public showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
    width: string | null,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data, nzStyle: { width: width ?? '385px' } });
  }

  public processError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return _throw({ code: 0, messages: ['Application crashed. Please try to refresh the page.'] });
    }
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,

    if (error.status === 400) {
      const messages = [];
      if (error.error.errors) {
        for (const i in error.error.errors) {
          messages.push(error.error.errors[i]);
        }
      }
      return _throw({ code: 400, messages });
    }

    if (error.status === 401) {
      if (error.error.hasOwnProperty('has_access_limited')) {
        return _throw({ code: 401, data: error.error });
      } else {
        return _throw({ code: 401, messages: [error.error.error] });
      }
    }

    if (error.status === 403) {
      return _throw({ code: 403, messages: [error.error.message] });
    }

    if (error.status === 404) {
      return _throw({
        code: 404, messages: error.error.error === 'Partner not found'
          ? [error.error.error]
          : ['Can\'t process the request. Requested URL not found.']
      });
    }

    if (error.status === 422) {
      const errors = error.error.errors;
      const messages = errors ? Object.values(errors).map((item) => {
        if (typeof item === 'object') {
          const errorsArray = Object.values(item).flat().join('\r\n');
          return errorsArray;
        } else {
          return item;
        }
      }) : [];
      return _throw({ code: 422, messages, errors });
    }

    if (error.status === 500) {
      const messages = [];
      if (error.error.errors) {
        for (const i in error.error.errors) {
          messages.push(error.error.errors[i]);
        }

        return _throw({ code: 400, messages });
      } else {
        return _throw({ code: 500, messages: ['Server error. Please try again later.'] });
      }
    }

    if (error.status === 503) {
      return _throw({ code: 503, messages: ['Service Unavailable'] });
    }

    return _throw({ code: 0, messages: ['An error occurred. Please try again later.'] });
  }

  private handleUnauthorizedError(error: any): void {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('token');

    // Generate the appropriate error message
    const errorMessage = this.getErrorMessage(token, error.messages);

    // Show the error notification to the user
    this.showNotification('error', 'error', 'error', errorMessage, null);

    // Close all open modals
    this.modalService.closeAll();

    // Log out the user
    this.authService.logout();

    // Navigate to the login page
    this.router.navigate(['login']);
  }


  private getErrorMessage(token: string | null, messages: string[]): string {
    // Check if the token is expired and return the corresponding message
    return token && this.authService.isExpired(this.authService.expiredDate)
      ? 'Your authentication token has been expired. Please log in again to continue using the platform'
      : messages.join('\r\n');
  }
}
