<div class="dropdown-component" [class.opened]="opened">
  <button class="btn dropdown-component__button" (click)="toggleOpened($event)">
    <img class="icon-table" src="assets/img/ic_sort_table.svg" alt="" />
    <img class="icon-arrow" src="assets/img/ic_arrow_drop_down.svg" alt="" />
  </button>
  <div
    [ngClass]="
      type === 'reports'
        ? 'dropdown-component__content'
        : 'dropdown-component__builder'
    "
    (click)="onDropdownBodyClicked($event)"
  >
    <div
      [ngClass]="type === 'reports' ? 'dropdown__contents' : 'dropdown__items'"
    >
      <div
        class="dropdown__item"
        *ngFor="let item of items"
        (click)="toggleSelection($event, item.attr, item.active)"
      >
        <div class="dropdown__item__inner" [class.disabled]="item.disabled">
          <img
            class="icon-checked"
            src="assets/img/ic_check_gray.svg"
            *ngIf="item.active && item.label !== ''"
          />
          <p *ngIf="item.label !== ''">{{ item.label }}</p>
        </div>
      </div>
    </div>
    <button
      *ngIf="type === 'reports'"
      type="button"
      (click)="apply()"
      class="btn btn-apply"
    >
      Apply
    </button>
  </div>
</div>
