import { AfterContentInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';
import { Observable, Observer } from 'rxjs';

import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NgxSpinnerService } from 'ngx-spinner';

import { ErrorsHandlingService, NotificationService } from '../../../../core/services';
import { NotificationComponent } from '../../../../shared/components';

import {
  allowedExtensionsFormatMessage,
  maxFileSizeMessage,
  allowedFileTypes,
} from '../../models';
import { AskSupportService } from '../../services';

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

@Component({
  selector: 'app-ask-support',
  templateUrl: './ask-support.component.html',
  styleUrls: ['./ask-support.component.scss']
})
export class AskSupportComponent implements OnInit, AfterContentInit {
  @Output() public closeModal = new EventEmitter<void>();
  @Output() public submitForm = new EventEmitter<void>();

  protected askSupportForm: FormGroup;
  protected fileList: NzUploadFile[] = [];
  protected previewImage: string | undefined = '';
  protected previewVisible = false;

  protected priorityOptions: { id: string; name: string; tooltip: string }[] = [
    {
      id: 'high', name: 'High', tooltip: `Urgent critical functionality impact: performance drop, 
      statistics malfunction, request/responses processing issue` },
    { id: 'medium', name: 'Medium', tooltip: 'Significant impact on workflow: UI glitches, delays' },
    { id: 'low', name: 'Low', tooltip: 'Minor issue or question' },
  ];

  protected modalHeight = 'auto';
  protected modalBodyHeight = 'auto';
  protected contentHeight = 'auto';
  protected contentMaxHeight = 'auto';

  private allowedExtensionsFormatMessage = allowedExtensionsFormatMessage;
  private maxFileSizeMessage = maxFileSizeMessage;
  private allowedFileTypes = allowedFileTypes;

  private fileUploadedList: File[] | NzUploadFile[] = [];

  constructor(
    protected askSupportService: AskSupportService,
    private fb: FormBuilder,
    private loadingService: NgxSpinnerService,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
    private errorsHandlingService: ErrorsHandlingService,
  ) { }

  public ngOnInit(): void {
    this.createForm();
  }

  public ngAfterContentInit(): void {
    this.modalHeight = window.innerHeight - 60 + 'px';
    this.modalBodyHeight = window.innerHeight - 80 + 'px';
    this.contentHeight = window.innerHeight - 35 + 'px';
    this.contentMaxHeight = window.innerHeight - 75 + 'px';
    window.addEventListener('resize', () => {
      this.modalHeight = window.innerHeight - 60 + 'px';
      this.modalBodyHeight = window.innerHeight - 80 + 'px';
      this.contentHeight = window.innerHeight - 35 + 'px';
      this.contentMaxHeight = window.innerHeight - 75 + 'px';
    });
  }

  protected get isDisabledButton(): boolean {
    return this.fileList.length === 5;
  }

  public get titleValidStatus(): string {
    return this.askSupportForm.controls.title.touched &&
      this.askSupportForm.controls.title?.errors?.required ? 'error' : 'success';
  }

  public get descriptionValidStatus(): string {
    return this.askSupportForm.controls.description.touched &&
      this.askSupportForm.controls.description?.errors?.required ? 'error' : 'success';
  }

  public get priorityValidStatus(): string {
    return this.askSupportForm.controls.priority.touched &&
      this.askSupportForm.controls.priority?.errors?.required ? 'error' : 'success';
  }

  protected onCancel(): void {
    this.closeModal.emit();
  }

  protected beforeUpload = (file: NzUploadFile, _: NzUploadFile[]): Observable<boolean> =>
    new Observable((observer: Observer<boolean>) => {
      const isAllowedType = file.type ? this.allowedFileTypes.includes(file.type) : false;
      if (!isAllowedType) {
        this.errorsHandlingService.showNotification('error', 'error', 'error', this.allowedExtensionsFormatMessage, null);
        observer.complete();
        return;
      }
      const isLt100M = file.size ? file.size / 1024 / 1024 < 100 : false;
      if (!isLt100M) {
        this.errorsHandlingService.showNotification('error', 'error', 'error', this.maxFileSizeMessage, null);
        observer.complete();
        return;
      }
      observer.next(isAllowedType && isLt100M);
      observer.complete();
    });

  protected handleRemove = (file: NzUploadFile): boolean => {
    const { uid } = file;
    this.fileUploadedList = (this.fileUploadedList as NzUploadFile[]).filter(item => item.uid !== uid);
    return true;
  };

  protected handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      if (file.originFileObj) {
        file.preview = await getBase64(file.originFileObj);
      }
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = file.thumbUrl ? file.thumbUrl.includes('data:image/') : false;
  };

  protected onFormSubmit(): void {
    if (this.askSupportForm.valid) {
      this.loadingService.show();
      this.askSupportService.sendAskSupport({ ...this.askSupportForm.value, filesList: this.fileUploadedList })
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(() => {
          this.showNotification('success', 'success', 'success', 'Your message was successfully received');
          this.submitForm.emit();
        },
          error => this.errorsHandlingService.handleError(error));
    }
  }

  private handleFile(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      e.target?.result as string;
    };
    reader.readAsDataURL(file);
  }

  protected handleUpload(uploadParam: NzUploadChangeParam) {
    if (uploadParam.type === 'error') {
      this.errorsHandlingService.showNotification(
        'error',
        'error',
        'error',
        'The file you are trying to upload is too big. Please, try uploading a different file',
        null
      );

      this.fileList = [];
    }

    if (uploadParam.type === 'success') {
      this.fileUploadedList = [];
      this.fileList = uploadParam.fileList;
      uploadParam.fileList.forEach(file => {
        const nativeFile = file.originFileObj;
        if (nativeFile) {
          this.fileUploadedList.push(nativeFile as any);
          this.handleFile(nativeFile);
        }
      });
    }
  }

  protected deleteFile(): void {
    this.askSupportForm.get('filesList')?.setValue(null);
  }

  private createForm(): void {
    this.askSupportForm = this.fb.group({
      title: [null, Validators.required],
      description: [null, Validators.required],
      priority: [null, Validators.required],
    });
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data });
  }
}
