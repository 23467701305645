import { Component, Input } from '@angular/core';

import { PartnerInterfaceGlobal } from '../../../core/models';

@Component({
  selector: 'app-stat-profit',
  templateUrl: './stat-profit.component.html',
  styleUrls: ['./stat-profit.component.scss']
})
export class StatProfitComponent {
  @Input() public set profitItems(value: PartnerInterfaceGlobal.IStatGeneralItem[]) {
    this._profit = value;
  }

  public get profitItems(): PartnerInterfaceGlobal.IStatGeneralItem[] {
    return this._profit;
  }

  @Input() public set sspSpendItems(value: PartnerInterfaceGlobal.IStatGeneralItem[]) {
    this._sspSpend = value;
  }

  public get sspSpendItems(): PartnerInterfaceGlobal.IStatGeneralItem[] {
    return this._sspSpend;
  }

  @Input() public set dspSpendItems(value: PartnerInterfaceGlobal.IStatGeneralItem[]) {
    this._dspSpend = value;
  }

  public get dspSpendItems(): PartnerInterfaceGlobal.IStatGeneralItem[] {
    return this._dspSpend;
  }

  protected tabIndex = 0;

  protected tabItems = [
    {
      name: 'Profit',
      id: 0,
    },
    {
      name: 'SSP Spend',
      id: 1,
    },
    {
      name: 'DSP Spend',
      id: 2,
    }
  ];


  private _profit: PartnerInterfaceGlobal.IStatGeneralItem[] = [];
  private _sspSpend: PartnerInterfaceGlobal.IStatGeneralItem[] = [];
  private _dspSpend: PartnerInterfaceGlobal.IStatGeneralItem[] = [];

  constructor() { }

  onChangeTab(id: number): void {
    this.tabIndex = id;
  }
}
