import { Component } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  protected tabIndex = 0;

  protected tabItems = [
    {
      name: 'Ask for Support',
      id: 0,
      icon: 'ng-zorro:contactUsModal',
    },
    {
      name: 'Suggest feature',
      id: 1,
      icon: 'ng-zorro:suggestFeature',
    },
  ];

  constructor(
    private dialogRef: NzModalRef<ContactUsComponent>,
  ) { }

  protected onCloseModal(): void {
    this.dialogRef.close();
  }

  protected onChangeTab(id: number): void {
    this.tabIndex = id;
  }
}
