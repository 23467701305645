<div
  class="sidebar-menu"
  [style.height]="menuHeight"
  [ngClass]="isCollapsed ? 'sidebar-collapsed' : 'sidebar-menu'"
>
  <ul>
    <ng-container *ngFor="let item of menuItems">
      <li
        *ngIf="item.isShow"
        class="sidebar-menu-item"
        [ngClass]="{ active: item.isOpen }"
        [class.isLast]="item.isLast"
      >
        <a
          class="sidebar-item"
          (click)="onMenuItemClick($event, item)"
          [ngClass]="{
          'has-children': item.children,
          'menu-item-active': item.isOpen,
          'disabled': !alertsDrawerData.length && item.label === 'Alerts',
          'active': item.label === 'Contact Us',
        }"
          nz-tooltip
          nzTooltipOverlayClassName="item-tooltip"
          [nzTooltipTitle]="isCollapsed ? item.label : ''"
          nzTooltipPlacement="right"
        >
          <span class="sidebar-icon" nz-icon [nzType]="item.icon"></span>
          <span
            class="sidebar-item-text"
            *ngIf="!isCollapsed && sideBarWidth !== '80px'"
          >
            {{ item.label }}</span
          >
          <span
            class="sidebar-item-arrow"
            nz-icon
            nzType="ng-zorro:dropdownLeft"
            *ngIf="item.children && !isCollapsed"
            [ngClass]="{ rotated: item.isOpen }"
            (click)="toggleMenuItem(item)"
          ></span>
          <span
            class="sidebar-item-count"
            [ngClass]="{ collapsed: isCollapsed }"
            *ngIf="item.label === 'Alerts'"
          >
            {{ alertsCount }}
          </span>
        </a>

        <ul
          *ngIf="item.children && !isCollapsed && item.isOpen"
          class="sidebar-submenu"
        >
          <li *ngFor="let child of item.children">
            <a class="sidebar-item sidebar-subitem">
              <span
                class="sidebar-item-text"
                (click)="onMenuItemClick($event, child)"
                >{{ child.label }}</span
              >
              <span nz-icon [nzType]="child.icon"></span>
            </a>
          </li>
        </ul>
      </li>
    </ng-container>

    <span
      nz-icon
      nzType="ng-zorro:collapse"
      class="sidebar-toggle-btn"
      [ngClass]="{ 'sidebar-collapsed': isCollapsed }"
      (click)="onCollapseHandler()"
    ></span>
  </ul>
</div>
