import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HoverTrackerDirective } from './hover-tracker.directive';

@NgModule({
  declarations: [HoverTrackerDirective],
  imports: [CommonModule],
  exports: [HoverTrackerDirective]
})
export class HoverTrackerModule { }
