<div class="card-row" *ngFor="let item of planItems">
  <div class="card-row__text">
    {{ item.text }}
  </div>
  <div class="card-row__toggle">
    <nz-switch
      name="switch"
      [(ngModel)]="planItemControls[item.model]"
      [ngModelOptions]="{ standalone: true }"
      [nzDisabled]="item.disabled"
      (ngModelChange)="onChangePartnerPlan($event, item.id)"
    >
    </nz-switch>
  </div>
</div>
