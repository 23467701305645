<div class="contact-us-container">
  <div class="contact-us-content">
    <div class="contact-us__top">
      <div class="contact-us__title">Contact Us</div>
      <div class="contact-us__close">
        <span
          nz-icon
          nzType="close"
          nzTheme="outline"
          class="close-icon"
          style="color: #002199"
          (click)="onCloseModal()"
        ></span>
      </div>
    </div>
    <div class="contact-us__body">
      <nz-tabset (nzSelectedIndexChange)="onChangeTab($event)">
        <nz-tab *ngFor="let tab of tabItems" [nzTitle]="titleTemplate">
          <ng-template #titleTemplate>
            <div class="tab-item" [class.active]="tabIndex === tab.id">
              <span nz-icon [nzType]="tab.icon"></span>
              {{ tab.name }}
            </div>
          </ng-template>
          <div [ngSwitch]="tabIndex">
            <div *ngSwitchCase="0">
              <app-ask-support
                (closeModal)="onCloseModal()"
                (submitForm)="onCloseModal()"
              ></app-ask-support>
            </div>
            <div *ngSwitchCase="1">
              <app-suggest-feature
                (closeModal)="onCloseModal()"
                (submitForm)="onCloseModal()"
              ></app-suggest-feature>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</div>
