import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../../../core/services';

export const IsPartnerPlanNonEnterprise: CanActivateFn = () => {
  const auth = inject(AuthService);
  const router = inject(Router);

  if (auth.partner.partner_plan !== 1) {
    router.navigate(['ssp-dsp-dashboard']);

    return false;
  }

  return true;
};
