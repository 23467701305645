import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../../services';

export const IsBasicPartnerPlanGuard: CanActivateFn = () => {
  const auth = inject(AuthService);
  const router = inject(Router);

  if (auth.partner.partner_plan === 3) {
    router.navigate(['ssp-dsp-dashboard']);

    return false;
  }

  return true;
};
