<div
  class="columns-options-dropdown-active columns-options-dropdown-active--filter"
  *ngIf="filters.length > 0"
>
  <div class="form-group" style="width: 100%">
    <div class="table--filter">
      <button
        nz-button
        nzType="default"
        class="btn-filter"
        (click)="toggleState($event)"
      >
        <a style="color: #000000d9">Filter:</a>
        <div class="arrow">
          <i
            nz-icon
            nzType="down"
            nzTheme="outline"
            style="font-size: 12.5px; color: #a8b7c7"
          ></i>
        </div>
      </button>
      <div #filter class="table--filter__categories" *ngIf="isShowFilter">
        <ul class="criateries">
          <li *ngFor="let filter of filters">
            <div
              class="criateries__row"
              (click)="toggleStateEnabled($event, filter)"
              [ngClass]="{ active: filter.active }"
            >
              <span [ngClass]="{ 'active-span': filter.active }">{{
                filter.label
              }}</span>
              <i
                nz-icon
                nzType="down"
                [ngClass]="{ opened: filter.active }"
                nzTheme="outline"
                style="font-size: 12.5px; color: #a8b7c7"
              ></i>
            </div>
            <ng-container *ngIf="filter.active">
              <div class="criateries--sub">
                <nz-select
                  [(ngModel)]="filter.filters"
                  nzShowSearch
                  [nzShowArrow]="true"
                  (ngModelChange)="changeFilterOption($event, filter, side)"
                  nzMode="multiple"
                >
                  <nz-option
                    *ngFor="let item of filter.values"
                    [nzValue]="item.id"
                    [nzLabel]="item.name"
                    [nzDisabled]="item.disabled"
                    nzCustomContent
                  >
                    <span
                      nz-tooltip
                      nzTooltipOverlayClassName="item-tooltip"
                      [nzTooltipTitle]="item.tooltip"
                      nzTooltipPlacement="top"
                      >{{ item.name }}</span
                    >
                  </nz-option>
                </nz-select>
              </div>
            </ng-container>
          </li>
        </ul>
        <button
          class="btn-search"
          nz-button
          nzType="primary"
          (click)="search(side)"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</div>
