/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexFill,
  ApexLegend,
  ApexYAxis,
  ApexStroke,
  ApexTitleSubtitle,
  ApexAnnotations,
  ApexPlotOptions,
  ApexResponsive
} from 'ng-apexcharts';

export interface IDynamicChartReqData {
  region: string;
  ssp: number;
  dsp: number;
  metric: string;
  ad_format: number;
  traffic_type: number;
  os: number;
}

export interface IPerformanceChartReqData {
  date: string; // required -> string(last_3_days, today, yesterday, last_30_days)
  region: string; // required
  ssp: number;
  dsp: number;
  os: string;
  traffic_type: number;
  ad_format: number;
  geo: number;
}

export interface ISourceChartReqData {
  date: string; // required -> string(last_3_days, today, yesterday, last_30_days)
  region: string; // required
  ssp: number;
  dsp: number;
  os: string;
  traffic_type: number;
  ad_format: number;
  geo: number;
}

export interface IMetricOverviewChartReqData {
  date: string; // required -> string(last_7_days, last_3_days, today, yesterday, last_30_days)
  region: string; // required
  type: string// required -> string(ssp, dsp)
  endpoint: number;
  dsp: number;
  os: number;
  traffic_type: number;
  ad_format: number;
}
export interface ICard {
  color: string;
  textColor: string;
  column: string;
  enabled: boolean;
  show: boolean;
  name: string;
  value: string;
}

export interface IDataItem {
  date: string;
  [key: string]: string;
}

export interface IDataQPSChartRes {
  data: any[];
  config: {
    [key: string]: IConfigItem;
  };
  total: ITotalMetrics;
  qpsLimit?: number;
}

export interface IDataResForTotal extends Omit<IDataQPSChartRes, 'total' | 'qpsLimit'> {
  total: {
    dsp_price: TotalSubChartsRes;
    imp: TotalSubChartsRes;
    ssp_price: TotalSubChartsRes;
    platform_revenue: TotalSubChartsRes;
  }
  maxY: number;
}

export interface TotalSubChartsRes {
  data: { [key: string]: number | string }[];
  config: { [key: string]: IConfigItem; };
}

export interface IPieChartRes {
  data: {
    color: string;
    dsp: string;
    enabled: boolean;
    endpoint: string;
    value: number;
    name: string;
  }[];
  debug: any;
}

export interface IPerformanceTotalData {
  color: string;
  dsp: string;
  enabled: boolean;
  endpoint: string;
  value: number;
  name: string;
}

export interface IPieChartBaseTableData {
  color: string;
  enabled: boolean;
  value: number;
  name: string;
}

export interface IPieChartSourceTableData extends Omit<IPerformanceTotalData, 'dsp' | 'endpoint'> {
  inventory_key: string;
}

export type ISourceChartRes = {
  inventory_key: string;
} & {
  name: string;
  value: any;
  color: string;
  enabled: boolean;
  endpoint?: string;
  dsp?: string;
  inventory_key?: string;
};


interface IConfigItem {
  name: string;
  tooltip?: string;
  chart: {
    axis: string;
    type?: string;
  };
}

interface ITotalMetrics {
  [key: string]: string | number;
}

interface IChartSeries {
  field: string;
  name: string;
  color: string;
  data: any[];
}

interface IDynamicChartSeries {
  field: string;
  name: string;
  colors: string[];
  data: any[];
}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  annotations: ApexAnnotations;
  toolbar: boolean;
  plotOptions: ApexPlotOptions;
  colors: string[];
  labels: any;
  responsive: ApexResponsive[];
};

export interface IChartTransformData {
  series: IChartSeries[];
  colors: { name: string; color: string }[];
  limitedSeries: IChartSeries[];
  chartOptions: Partial<ChartOptions>;
  total: any;
}

export interface IChartDynamicSeries extends Omit<IChartSeries, 'data'> {
  data: { x: string | number, y: number }[]
}

export interface IChartDynamicTransformData extends Omit<IChartTransformData, 'series' | 'limitedSeries'> {
  series: IChartDynamicSeries[];
  limitedSeries: IChartDynamicSeries[];
}

export interface IChartMetricTransformData extends Omit<IChartTransformData, 'series' | 'limitedSeries'> {
  series: IChartSeries[];
  limitedSeries: IChartSeries[];
  transformedData: any[];
  name: string;
  chartColors: {
    name: string;
    color: string;
  }[];
  chartColumns: {
    [key: string]: { name: string; };
  };
  modifiedData: { [key: string]: number | string }[];
}

export interface IChartTotalSeries extends Omit<IDynamicChartSeries, 'data' | 'field' | 'name'> {
  data: { [key: string]: string | number }[];
}

export interface IChartTransformDataForTotal extends Omit<IChartTransformData, 'total' | 'series' | 'limitedSeries' | 'colors'> {
  series: IChartTotalSeries[];
  limitedSeries: IChartTotalSeries[];
}