import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PartnerInterfaceGlobal } from '../../../core/models';
import { IAlert } from '../../../core/interfaces';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent {
  @Input() public set menuHeight(value: string) {
    this._menuHeight = value;
  }

  public get menuHeight(): string {
    return this._menuHeight;
  }

  @Input() public set sideBarWidth(value: string) {
    this._sideBarWidth = value;
  }

  public get sideBarWidth(): string {
    return this._sideBarWidth;
  }

  @Input() public isCollapsed = false;

  @Input() public menuItems: PartnerInterfaceGlobal.IStatMenuItem[] = [];

  @Input() public alertsDrawerData: IAlert[] = [];

  @Input() public alertsCount = 0;

  @Output() public collapseHandler = new EventEmitter<void>();

  @Output() public menuItemClick = new EventEmitter<{
    event: Event;
    item: PartnerInterfaceGlobal.IStatMenuItem;
  }>();

  @Output() public toggleMenuItemHandler = new EventEmitter<PartnerInterfaceGlobal.IStatMenuItem>();


  private _menuHeight = 'auto';
  private _sideBarWidth = '260px';

  constructor() { }

  protected onMenuItemClick(event: Event, item: PartnerInterfaceGlobal.IStatMenuItem): void {
    const emittedData = { event, item };
    this.menuItemClick.emit(emittedData);
  }

  protected toggleMenuItem(item: PartnerInterfaceGlobal.IStatMenuItem): void {
    this.toggleMenuItemHandler.emit(item);
  }

  protected onCollapseHandler(): void {
    this.collapseHandler.emit();
  }
}
