import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { StatHeaderComponent } from './stat-header.component';
import { QpsHeadItemModule } from '../qps-head-item';
import { ClickOutsideModule } from '../../directives';

@NgModule({
  declarations: [StatHeaderComponent],
  exports: [StatHeaderComponent],
  imports: [
    CommonModule,
    QpsHeadItemModule,
    ClickOutsideModule,
    NzTabsModule,
    NzIconModule,
  ]
})
export class StatHeaderModule { }
