<div class="delete-dialog">
  <div class="delete-dialog__top" *nzModalTitle>
    {{ data.title }}
  </div>
  <div class="delete-dialog__content content-flex">
    <div class="content-flex__text" [innerHTML]="data.message"></div>
    <div
      class="content-flex__warning"
      [style.font-weight]="data.fontWeight"
      *ngIf="data.isWarningMessage"
    >
      {{ data.warningMessage }}
    </div>
  </div>
  <div *nzModalFooter class="button-footer">
    <button type="submit" nz-button nzType="primary" (click)="onConfirm()">
      {{ data.buttonSaveText }}
    </button>
    <button
      type="button"
      nz-button
      nzType="default"
      class="btn-cancel"
      (click)="onCancel()"
    >
      {{ data.buttonCancelText }}
    </button>
  </div>
</div>
