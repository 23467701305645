import { TableColumnInterface } from '../models';

export const adminGeneralTableColumns: TableColumnInterface.ITableColumn[] = [
  {
    label: 'ID',
    key: 'id',
    sortable: false,
    width: '150px'
  },
  {
    label: 'Name',
    key: 'admin_name',
    sortable: false,
    width: '150px'
  },
  {
    label: 'Use SSL',
    key: 'useSsl',
    sortable: false,
    width: '150px'
  },
  {
    label: 'Not Payment',
    key: 'not_paid',
    sortable: false,
    width: '150px'
  },
  {
    label: 'Alerts',
    key: 'is_slack_alerts',
    sortable: false,
    width: '150px'
  },
  {
    label: 'UI Deactivation',
    key: 'allPartnerUsersIsDisabled',
    sortable: false,
    width: '150px'
  },
  {
    label: 'Automatic access limitation',
    key: 'has_automatic_access_limitation',
    sortable: false,
    width: '300px'
  },
  {
    label: 'Action',
    key: 'action',
    sortable: false,
    width: '200px'
  }
];


export const adminModuleColumns: TableColumnInterface.ITableColumn[] = [
  {
    label: 'ID',
    key: 'id',
    sortable: false,
    width: '150px'
  },
  {
    label: 'Name',
    key: 'admin_name',
    sortable: false,
    width: '150px'
  },
  {
    label: 'Partner Plan',
    key: 'partner_plan',
    sortable: false,
    width: '250px'
  },
  {
    label: 'WL Prebid adaptor',
    key: 'wl_prebid',
    sortable: false,
    width: '200px'
  },
  {
    label: 'Prebid.JS adaptor',
    key: 'js_prebid',
    sortable: false,
    width: '200px'
  },
  {
    label: 'Traffic Analyzer',
    key: 'is_traffic_analyzer',
    sortable: false,
    width: '150px'
  },
  {
    label: 'Action',
    key: 'action',
    sortable: false,
    width: '200px'
  }
];


export const incomingTrafficLoggerColumns = {
  1: [{ id: 'ssp', name: 'SSP', width: '' }],
  2: [{ id: 'sitelist', name: 'Source', width: '' }],
  3: [{ id: 'ssp', name: 'SSP', width: 250 }, { id: 'domain', name: 'Source', width: '' }],
  4: [{ id: 'geo', name: 'GEO', width: '' }],
  6: [{ id: 'ssp', name: 'SSP', width: '' }, { id: 'geo', name: 'GEO', width: 100 }],
  7: [{ id: 'domain', name: 'Source' }, { id: 'geo', name: 'GEO', width: 100 }],
};


export const samplesLoggerTableColumns: TableColumnInterface.ITableColumn[] = [
  { label: 'Log Time', key: 'log_time', sortable: true, width: '180px' },
  { label: 'SSP', key: 'ssp_name', sortable: true, width: '250px' },
  { label: 'DSP', key: 'dsp_name', sortable: true, width: '250px' },
  { label: 'Log Event', key: 'log_event', sortable: true, width: '300px' },
  { label: 'Incoming Request', key: 'log_event', sortable: true, width: '180px' },
  { label: 'SSP Bid Request', key: 'ssp_request', sortable: true, width: '200px' },
  { label: 'DSP Bid Request', key: 'dsp_request', sortable: true, width: '200px' },
  { label: 'DSP Bid Response', key: 'dsp_response', sortable: true, width: '200px' },
  { label: 'SSP Bid Response', key: 'ssp_response', sortable: true, width: '200px' },
];