import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';


@Directive({
  selector: '[appHoverTracker]'
})
export class HoverTrackerDirective {
  @Input() hoverId!: string; // Unique identifier for each element
  @Output() hoverChange = new EventEmitter<string | null>(); // Emits when hover state changes

  @HostListener('mouseenter') onMouseEnter() {
    this.hoverChange.emit(this.hoverId); // Emit the hover ID
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hoverChange.emit(null); // Emit null on leave
  }
}