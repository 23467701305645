import { NzFormModule } from 'ng-zorro-antd/form';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzModalModule } from 'ng-zorro-antd/modal';

import {
  AskSupportComponent,
  SuggestFeatureComponent,
} from './components';
import { ContactUsComponent } from './pages';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
@NgModule({
  declarations: [
    AskSupportComponent,
    SuggestFeatureComponent,
    ContactUsComponent
  ],
  imports: [
    CommonModule,
    NzInputModule,
    NzButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NzIconModule,
    NzImageModule,
    NzUploadModule,
    NzModalModule,
    NzTabsModule,
    NzFormModule,
    NzSelectModule,
    NzToolTipModule,
  ],
  exports: [ContactUsComponent],
})
export class ContactUsModule { }
