import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {

  constructor(private fb: FormBuilder) { }

  public createDynamicFormGroup(inputArray: { controlName: string, controlValue: any, validators: string[], disabled: boolean }[]): FormGroup {
    const dynamicForm = this.fb.group({});

    const validatorFunctions: { [key: string]: ValidatorFn } = {
      required: Validators.required,
      minLength: Validators.minLength(8), // You can set the desired minLength here
      email: Validators.email,
      // Add more validators here as needed
    };

    inputArray.forEach(item => {
      const validatorsArray = item.validators.map(validator => validatorFunctions[validator]);

      // Create the form control with validators and disabled state
      const control = new FormControl({ value: item.controlValue, disabled: item.disabled }, validatorsArray);
      dynamicForm.addControl(item.controlName, control);
    });

    return dynamicForm;
  }

  public updateFormWithValues(formGroup: FormGroup, valuesToUpdate: { [key: string]: any }): FormGroup {
    // Create a new form group with the same form controls
    const updatedFormGroup = this.fb.group({});

    // Copy the form controls and their values from the original form group to the new one
    Object.keys(formGroup.controls).forEach(key => {
      updatedFormGroup.addControl(key, formGroup.get(key));
    });

    // Update the values in the new form group
    for (const key in valuesToUpdate) {
      if (valuesToUpdate.hasOwnProperty(key)) {
        if (updatedFormGroup.get(key)) {
          updatedFormGroup.get(key).patchValue(valuesToUpdate[key]);
        }
      }
    }

    return updatedFormGroup;
  }

  public endpointFormWithValues(formGroup: FormGroup, valuesToUpdate: { [key: string]: any }, checkboxes: string[]): FormGroup {
    const updatedFormGroup = this.fb.group({});

    Object.keys(formGroup.controls).forEach(key => {
      updatedFormGroup.addControl(key, formGroup.get(key));
    });

    for (const key in valuesToUpdate) {
      if (valuesToUpdate.hasOwnProperty(key)) {

        if (checkboxes.includes(key)) {
          updatedFormGroup.get(key).patchValue(valuesToUpdate[key] === 1 ? true : false, { emitEvent: false });
          continue;
        }

        if (updatedFormGroup.get(key)) {
          updatedFormGroup.get(key).patchValue(valuesToUpdate[key], { emitEvent: false });
        }
      }
    }

    return updatedFormGroup;
  }

  public createSSPForm(): FormGroup {
    const form = this.fb.group({
      active: [false],
      connectionType: ['rtb'],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      isSecure: [false],
      vastConnectionMode: ['vastToAll'],
      rtbConnectionMode: [0],
      isBanner: [false],
      isNative: [false],
      isPop: [false],
      isVideo: [false],
      is_gpp_support: [false],
      isPush: [false],
      popRespXml: [false],
      rewarded: [false],
      isAudio: [false],
      mobile_web: [false],
      mobile_app: [false],
      desktop: [false],
      ctv: [false],
      adtype: ['mobileWeb'],
      vast: this.fb.group({
        bidfloor: [null],
        w: [null, Validators.required],
        h: [null, Validators.required],
        ctv: [0],
        mobileWeb: [0],
        inApp: [0],
        desktop: [0],
        macrosList: [[]],
        selectedSizes: [0],
      }),
      fixedPrice: [false],
      defaultBidfloor: [null],
      companyId: [null],
      region: [null],
      defaultTmax: [null],
      margin_type: ['fixed'],
      margin: [null],
      min_margin: [undefined],
      max_margin: [undefined],
      spendLimit: [null],
      js: this.fb.group({
        w: [null],
        h: [null],
        tagType: ['web'],
        macrosList: [null],
        categories: [null],
        backfill: [null],
        bidfloor: [null],
        jsTag: [{ value: null, disabled: true }],
      }),
      selectedSize: [0],
      apiLink: [null],
      id: [null],
      endpoint: [null],
      whiteops_enable_postbid: [false],
      botman_enable_postbid: [false],
      botman_enable_prebid: [false],
      useTMT: [false],
      useForensiq: [false],
      usePMKey: [false],
      is_pixalate_postbid: [false],
      useGeoedge: [false],
      usePMPrebid: [false],
      pmIsFraud: [0],
      is_iurl_filter: [false],
      useBcatFilter: [false],
      has_badv_filter: [false],
      gzipResponses: [false],
      isForTest: [false],
      use_secure_filter: [false],
      has_response_adomain: [false],
      has_demand_booster: [false],
      has_maximised_bidprice: [false],
      writing_pub_ids: ['automated'],
      iab_categories: [null],
      allowedDspListTrigger: [1],
      dspList: [null],
      imp_triggering: ['adm'],
      geoedge_key: [''],
      pixalate_pb_settings: this.fb.group({
        ip: [false],
        ip_percent: [null],
        ifa: [false],
        ifa_percent: [null],
        ua: [false],
        ott: [false],
        ott_percent: [null],
        def_app: [false],
        domain: [false],
        hr_app: [false],
        hr_app_percent: [null],
        dc_subnet: [false],
      }),
      is_origin_device_object: false,
    });


    return form;
  }

  public createEditSSPForm(): FormGroup {
    return this.fb.group({
      active: [false],
      connectionType: ['rtb'],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      isSecure: [false],
      vastConnectionMode: ['vastToAll'],
      rtbConnectionMode: [0],
      isBanner: [false],
      isNative: [false],
      isPop: [false],
      isVideo: [false],
      isPush: [false],
      popRespXml: [false],
      rewarded: [false],
      isAudio: [false],
      is_gpp_support: [false],
      mobile_web: [false],
      mobile_app: [false],
      desktop: [false],
      id: [null],
      ctv: [false],
      adtype: ['mobileWeb'],
      vast: this.fb.group({
        bidfloor: [null],
        w: [null],
        h: [null],
        ctv: [0],
        mobileWeb: [0],
        inApp: [0],
        desktop: [0],
        macrosList: [[]],
        selectedSizes: [0],
        adServerId: [null],
      }),
      fixedPrice: [false],
      defaultBidfloor: [null],
      companyId: [null],
      region: [null],
      defaultTmax: [null],
      margin_type: ['fixed'],
      margin: [null],
      min_margin: [undefined],
      max_margin: [undefined],
      spendLimit: [null],
      js: this.fb.group({
        w: [null],
        h: [null],
        tagType: ['web'],
        macrosList: [null],
        categories: [null],
        backfill: [null],
        bidfloor: [null],
        jsTag: [{ value: null, disabled: true }],
      }),
      selectedSize: [0],
      apiLink: [null],
      endpoint: [{ value: null, disabled: true }],
      is_iurl_filter: [false],
      whiteops_enable_postbid: [false],
      botman_enable_postbid: [false],
      botman_enable_prebid: [false],
      useTMT: [false],
      useForensiq: [false],
      usePMKey: [false],
      is_pixalate_postbid: [false],
      useGeoedge: [false],
      usePMPrebid: [false],
      pmIsFraud: [0],
      useBcatFilter: [false],
      has_badv_filter: [false],
      gzipResponses: [false],
      isForTest: [false],
      use_secure_filter: [false],
      has_response_adomain: [false],
      has_demand_booster: [false],
      has_maximised_bidprice: [false],
      writing_pub_ids: ['automated'],
      iab_categories: [null],
      allowedDspListTrigger: [1],
      dspList: [null],
      reportingApiLink: [{ value: null, disabled: true }],
      partnerName: [{ value: null, disabled: true }],
      seat: [{ value: null, disabled: true }],
      secretKey: [{ value: null, disabled: true }],
      imp_triggering: ['adm'],
      geoedge_key: [''],
      pixalate_pb_settings: this.fb.group({
        ip: [false],
        ip_percent: [null],
        ifa: [false],
        ifa_percent: [null],
        ua: [false],
        ott: [false],
        ott_percent: [null],
        def_app: [false],
        domain: [false],
        hr_app: [false],
        hr_app_percent: [null],
        dc_subnet: [false],
      }),
      email: [null],
      comments: [null],
      deletedAt: [null],
      adapter_id: [null],
      filter_list_ids: [null],
      jsAdType: ['mobileWeb'],
      fixedBidPrice: [0],
      is_origin_device_object: false,
    });
  }

  public createDSPForm(): FormGroup {
    return this.fb.group({
      active: [false],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      connectionType: ['rtb'],
      rtb_type: ['2.5'],
      sChain: [false],
      isSspSchain: [false],
      schain_source: [false],
      schain_limit: [0],
      is_schain_complete: [false],
      schain_filter_option: [1],
      schain_complete_option: ['origin'],
      schainCompany: [1],
      useAdapter: [true],
      vastConnectionMode: ['vastToAll'],
      video: [false],
      audio: [false],
      adtype: ['mobileWeb'],
      banner: [false],
      native: [false],
      nativeSpec: [[-1]],
      pop: [false],
      push: [false],
      rewarded: [false],
      mobileWeb: [false],
      min_rcpm: [0],
      inApp: [false],
      desktop: [false],
      ctv: [false],
      companyId: [null],
      region: ['US_EAST'],
      margin_type: ['fixed'],
      margin: [null],
      min_margin: [null],
      max_margin: [null],
      defaultPriceVast: [null],
      js: this.fb.group({
        cpm: [null],
        w: [null],
        h: [null],
      }),
      rtbConnectionMode: [0],
      spendLimit: [null],
      limitQPS: [null],
      auctionType: [3],
      minTmax: [null],
      maxBidfloor: [null],
      selectedSize: [0],
      selectedPreBidServer: [null],
      selectedAdapters: [0],
      endpoint: [null],
      nurl: [null],
      apiLink: [null],
      id: [null],
      countriesTrigger: [1],
      countriesList: [[]],
      sspTrigger: [1],
      sspList: [[]],
      sizeTrigger: [1],
      sizesList: [[]],
      playerSizes: this.fb.group({
        trigger: [1],
        size: [[]],
        value: [[]]
      }),
      connectionTypeTrigger: [1],
      connectionTypesList: [[]],
      osTrigger: [1],
      osList: [[]],
      pmPrebidSuspiciousIVT: [0],
      pmPrebidFraudIVT: [0],
      mismatchedIpFilter: [false],
      mismatchedBundlesFilters: [false],
      iabOttCtv: [false],
      useTCF: [false],
      is_only_with_consent: [false],
      ccpa_check: [true],
      deviceIdRequired: [false],
      allow_synth_ifa: [false],
      withPlacementOnly: [false],
      is_omsdk: [false],
      ipv4_required: [false],
      has_badv_limit: [false],
      is_requests_empty_appname: [false],
      has_iab_categories_limit: [false],
      is_pixalate_postbid: [false],
      botman_enable_postbid: [false],
      whiteops_enable_postbid: [false],
      exclude_mraid_response: [false],
      mraid_impression_pixel: [false],
      pixalate_pb_settings: this.fb.group({
        ip: [false],
        ip_percent: [0],
        ifa: [false],
        ifa_percent: [0],
        ua: [false],
        ott: [false],
        ott_percent: [0],
        def_app: [false],
        domain: [false],
        hr_app: [false],
        hr_app_percent: [0],
        dc_subnet: [false],
      }),
      secureOnly: [0],
      filterPorn: [0],
    });
  }

  public createEditDSPForm(): FormGroup {
    return this.fb.group({
      active: [false],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      connectionType: ['rtb'],
      rtb_type: ['2.5'],
      sChain: [false],
      isSspSchain: [false],
      schain_source: [false],
      schain_filter_option: [1],
      schain_complete_option: ['origin'],
      schain_limit: [0],
      schainCompany: [1],
      is_schain_complete: [false],
      useAdapter: [true],
      vastConnectionMode: ['vastToAll'],
      rtbConnectionMode: [0],
      video: [false],
      audio: [false],
      adtype: ['mobileWeb'],
      jsAdtype: ['mobileWeb'],
      banner: [false],
      native: [false],
      nativeSpec: [[-1]],
      pop: [false],
      push: [false],
      rewarded: [false],
      mobileWeb: [false],
      inApp: [false],
      desktop: [false],
      ctv: [false],
      min_rcpm: [0],
      companyId: [null],
      region: ['US_EAST'],
      margin_type: ['fixed'],
      margin: [null],
      min_margin: [null],
      max_margin: [null],
      defaultPriceVast: [null],
      js: this.fb.group({
        cpm: [null],
        w: [null],
        h: [null],
      }),
      spendLimit: [null],
      limitQPS: [null],
      auctionType: [3],
      minTmax: [null],
      maxBidfloor: [null],
      selectedSize: [0],
      selectedPreBidServer: [null],
      selectedAdapters: [0],
      endpoint: [null],
      reportingApiLink: [null],
      nurl: [null],
      apiLink: [null],
      id: [null],
      countriesTrigger: [1],
      countriesList: [[]],
      sspTrigger: [1],
      sspList: [[]],
      sizeTrigger: [1],
      sizesList: [[]],
      playerSizes: this.fb.group({
        trigger: [1],
        size: [[]],
        value: [[]]
      }),
      connectionTypeTrigger: [1],
      connectionTypesList: [[]],
      osTrigger: [1],
      osList: [[]],
      pmPrebidSuspiciousIVT: [0],
      pmPrebidFraudIVT: [0],
      mismatchedIpFilter: [false],
      mismatchedBundlesFilters: [false],
      iabOttCtv: [false],
      useTCF: [false],
      is_only_with_consent: [false],
      ccpa_check: [true],
      deviceIdRequired: [false],
      allow_synth_ifa: [false],
      withPlacementOnly: [false],
      is_omsdk: [false],
      ipv4_required: [false],
      has_badv_limit: [false],
      is_requests_empty_appname: [false],
      has_iab_categories_limit: [false],
      is_pixalate_postbid: [false],
      botman_enable_postbid: [false],
      whiteops_enable_postbid: [false],
      exclude_mraid_response: [false],
      mraid_impression_pixel: [false],
      pixalate_pb_settings: this.fb.group({
        ip: [false],
        ip_percent: [0],
        ifa: [false],
        ifa_percent: [0],
        ua: [false],
        ott: [false],
        ott_percent: [0],
        def_app: [false],
        domain: [false],
        hr_app: [false],
        hr_app_percent: [0],
        dc_subnet: [false],
      }),
      secureOnly: [0],
      filterPorn: [0],
      email: [null],
      comments: [null],
      deletedAt: [null],
      adapter_id: [null],
      filter_list_ids: [null],
      jsAdType: ['mobileWeb'],
      botman_allow_fraud: [null],
      adServerId: [null],
    });
  }
}
