import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-admin-switch',
  templateUrl: './admin-switch.component.html',
  styleUrls: ['./admin-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSwitchComponent {
  @Input() set control(value: FormControl) {
    this._control = value;
  }

  public get control(): FormControl {
    return this._control;
  }

  private _control = new FormControl();
}
