/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs';


import { messages } from '../../../core/constants';
import { PartnerInt } from '../../../core/interfaces';
import { UserRole } from '../../../core/enum';
import { AuthService, DataManagerService, NotificationService } from '../../../core/services';

import { NotificationComponent } from '../notification';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  protected selectedMenu: string;
  protected partner: PartnerInt;
  protected userRole: UserRole;

  protected userRoleEnum = UserRole;

  protected isCollapsed = false;


  private isShowAlert = false;
  private isShowSSLExpires = false;
  private dataSSLExpires: { days: number; message: string };
  private messages = messages;


  constructor(
    private route: ActivatedRoute,
    public auth: AuthService,
    private router: Router,
    private dataManager: DataManagerService,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
  ) { }

  public ngOnInit(): void {
    this.initializeRouteData();
    this.initializeAuthData();
    this.getLimitQpsNotification();

    if (this.auth.isAuthenticated()) {
      this.setupTechnicalWorksCheck();
      this.setupSSLExpiryCheck();
    }
  }

  protected restrictionTooltipMessage(field: string): string {
    return (this.partner?.partner_plan !== 1 && this.partner?.partner_plan !== 2) ? `${field} is available on Plus and Enterprise versions. 
    If you want to upgrade your platform, please contact our Business Development Manager` : '';
  }

  protected restrictionTooltipForNonEnterpriseMessage(field: string): string {
    return this.partner?.partner_plan !== 1 ? `${field} is available on Enterprise version.
    If you want to upgrade your platform, please contact our Business Development Manager` : '';
  }

  protected onNavigateHandler(url: string): void {
    this.router.navigate([url]);
  }

  private initializeRouteData(): void {
    this.route.data.subscribe((d) => {
      this.selectedMenu = d.selectedMenu;
    });
  }

  private initializeAuthData(): void {
    this.partner = this.auth.partner;
    this.userRole = this.auth.userRole;
  }

  private setupTechnicalWorksCheck(): void {
    timer(1000, 1800000).subscribe(() => {
      this.dataManager.checkTechnicalWorks().subscribe(data => {
        this.isShowAlert = +data.useShowAlert === 1;
        if (this.isShowAlert) {
          this.showNotification('error', 'error', 'error', null, this.messages[data.messageType], null, null, 10000, null, null, null);
        }
      });
    });
  }

  private setupSSLExpiryCheck(): void {
    timer(1000, 1800000).subscribe(() => {
      if (this.userRole === 'support' || this.userRole === 'manager') {
        this.dataManager.checkSSLExpiry().subscribe(res => {
          if (res.data) {
            this.dataSSLExpires = res.data;
            this.isShowSSLExpires = !this.isShowAlert && !!res.data;
            if (this.isShowSSLExpires) {
              this.showNotification('error', 'error', 'error', null, this.dataSSLExpires.message, null, null, 10000, null, null, null);
            }
          }
        });
      }
    });
  }

  private getLimitQpsNotification(): void {
    if (this.auth.isAuthenticated() && (this.auth.userRole === UserRole.viewer
      || this.auth.userRole === UserRole.manager || this.auth.userRole === UserRole.support)) {
      timer(2000, 1800000)
        .subscribe(() => {
          this.auth.checkLimitQPSForPartner()
            .subscribe(res => {
              const messages = this.generateWarningMessages(res);
              if (messages.length > 0 && this.router.url === '/') {
                messages.forEach(message => this.showNotification('error', 'error', 'error', null, message, null, null, 10000, null, null, null));
              }
            });
        });
    }
  }

  private generateWarningMessages(data: {
    [region: string]: {
      incoming: number | boolean;
      outgoing: number | boolean;
    };
  }): string[] {
    const messages: string[] = [];

    for (const region in data) {
      const incoming = data[region].incoming;
      const outgoing = data[region].outgoing;

      if (incoming && !outgoing) {
        messages.push(`Dear partner, Your revenue is being affected! The system cannot operate normally. Please note that incoming traffic volume exceeds the limit. This results in server overload. Currently, you are receiving ${incoming} QPS on the ${region} region. Please reach out to your SSP partners and ask them to adjust QPS limits on their side.`);
      } else if (!incoming && outgoing) {
        messages.push(`Dear partner, Your revenue is being affected! The system cannot operate normally. Please note that outgoing traffic volume exceeds the limit. This results in server overload. Currently, you are receiving ${outgoing} QPS on the ${region} region. Please adjust QPS limits on the DSP side.`);
      } else if (incoming && outgoing) {
        messages.push(`Dear partner, Your revenue is being affected! The system cannot operate normally. Please note that incoming and outgoing traffic volume exceeds the limit. This results in server overload. Currently, you are receiving ${incoming} incoming QPS and ${outgoing} outgoing QPS on the ${region} region. Please reach out to your SSP ask them to adjust QPS limits on their side and adjust QPS limits in the DSP settings.`);
      }
    }

    return messages;
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    leftPartOfText: string,
    description: string,
    rightPartOfText: string,
    width: string,
    duration: number,
    pauseOnHover: boolean,
    textColor: string,
    fontWeight: string,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
      leftPartOfText: leftPartOfText ?? '',
      rightPartOfText: rightPartOfText ?? '',
      textColor: textColor ?? '#000',
      fontWeight: fontWeight ?? 'normal'
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({
      nzData: data,
      nzStyle: { width: width ?? '385px' },
      nzDuration: duration ?? 4500,
      nzPauseOnHover: pauseOnHover ?? true,
    });
  }
}
