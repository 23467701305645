export const allowedExtensionsFormatMessage = `The format of the file you are trying to upload is not supported. 
Please, try converting your file to one of the following standards: .png. jpeg. jpg. .gif .svg .mp4 .mov .pdf .csv .xlsx .docx .txt `;

export const maxFileSizeMessage = 'The file you are trying to upload is too big. Please, try uploading a different file. Max size: 100 Mb';

export const maximumFilesLengthMessage = 'You can only upload a maximum of 8 files!';

export const allowedFileTypes = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/gif',
  'image/svg+xml',
  'video/mp4',
  'video/quicktime',
  'application/pdf',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain'
];