import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NzSwitchModule } from 'ng-zorro-antd/switch';

import { AdminSwitchComponent } from './admin-switch.component';

@NgModule({
  declarations: [AdminSwitchComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzSwitchModule,
  ],
  exports: [AdminSwitchComponent]
})
export class AdminSwitchModule { }
