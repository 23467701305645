import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppOptionsService, ErrorsHandlingService } from '../../../core/services';

@Injectable({
  providedIn: 'root'
})
export class AskSupportService {
  private apiUrl = '';

  constructor(
    private http: HttpClient,
    private options: AppOptionsService,
    private errorsHandlingService: ErrorsHandlingService,
  ) {
    this.apiUrl = this.options.getApiUrl();
  }

  public sendAskSupport(form: {
    title: string | Blob;
    description: string | Blob;
    priority: string | Blob;
    filesList: File[];
  }): Observable<{ status: string, message: string }> {
    const formData: FormData = new FormData();
    formData.append('title', form.title);
    formData.append('description', form.description);
    formData.append('priority', form.priority);
    if (form.filesList && form.filesList.length > 0) {
      form.filesList.forEach((file, id) => {
        if (!(file instanceof File)) {
          file = new File([file as File], (file as File).name, { type: (file as File).type, lastModified: (file as File).lastModified });
        }
        formData.append(`file-${id}`, file);
      });
    }

    return this.http.post<{ status: string, message: string }>(`${this.apiUrl}/ask-support`, formData)
      .pipe(catchError(this.errorsHandlingService.processError));
  }

  public get getImagesUrl(): string {
    return `${this.apiUrl}/suggest-feature/images`;
  }
}
