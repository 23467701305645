import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  constructor() {

  }

  public storesYourStatistics() {
    return `<p>The platform stores your statistics for 3 months, so we highly recommend you</p>
    <p>to systematically download all the data to have the current numbers in 3 months.</p> `;
  }

  public mismatchAvailablePeriod() {
    return '<p>Statistics available for 2 weeks from current date.</p> ';
  }

  public storesAnalyzerStatistics() {
    return '<p>The platform stores your statistics for 3 months, but the available time range for select is 1-7 days</p>';
  }

  public storeScannerStatistics(): string {
    return `<p>The platform stores your statistics for 1 month, so we highly recommend you</p>
    <p>to systematically download all the data to have the current numbers in 1 month.</p> `;
  }
}
