import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { forkJoin } from 'rxjs';

import { NotificationService } from '../notification';
import { ErrorsHandlingService } from '../errors-handling';
import { DataManagerService } from '../data-manager';
import { alertForm, statistic } from '../../constants';

import { AlertsService } from '../../../modules/alerts/services';
import { AlertInterface } from '../../../modules/alerts/models';
import { PartnerInterfaceGlobal } from '../../models';
import { AuthService } from '../auth';

interface IRegionItem {
  name: string;
  partnerQps: {
    incomingReal: number;
    outgoingReal: number;
    incomingStandart: number;
    outgoingStandart: number;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FillStateService {
  public regionItems$ = new BehaviorSubject<IRegionItem[]>([]);
  public adFormatItems$ = new BehaviorSubject<PartnerInterfaceGlobal.IStatAdFormatItem[]>([]);
  public trafficTypeItems$ = new BehaviorSubject<PartnerInterfaceGlobal.IStatAdFormatItem[]>([]);

  public alertsData = new BehaviorSubject<AlertInterface.IAlert[]>([]);
  public total = new BehaviorSubject<number>(100);
  public alertsTableData = new BehaviorSubject<AlertInterface.IAlert[]>([]);
  public alertsDataObs = this.alertsData.asObservable();
  public total$ = this.total.asObservable();
  public alertsTableData$ = this.alertsTableData.asObservable();

  public profitArray: PartnerInterfaceGlobal.IStatGeneralItem[] = [];
  public sspSpendArray: PartnerInterfaceGlobal.IStatGeneralItem[] = [];
  public dspSpendArray: PartnerInterfaceGlobal.IStatGeneralItem[] = [];
  public todayProfit = 0;
  public yesterdayProfit = 0;

  public profit$ = new BehaviorSubject<PartnerInterfaceGlobal.IStatGeneralItem[]>([]);
  public sspSpend$ = new BehaviorSubject<PartnerInterfaceGlobal.IStatGeneralItem[]>([]);
  public dspSpend$ = new BehaviorSubject<PartnerInterfaceGlobal.IStatGeneralItem[]>([]);

  private regionItems: IRegionItem[] = [];
  private adFormatItems: PartnerInterfaceGlobal.IStatAdFormatItem[] = [];
  private trafficTypeItems: PartnerInterfaceGlobal.IStatAdFormatItem[] = [];
  private statistic = statistic;

  constructor(
    private readonly alertsService: AlertsService,
    private notificationService: NotificationService,
    private errorsHandlingService: ErrorsHandlingService,
    private dataManagerService: DataManagerService,
    private authService: AuthService,
  ) { }


  public handle(): void {
    if (this.authService.partner?.partner_plan !== 3) {
      this.alertsService.getAlerts({}).subscribe(res => {
        if (res.firstAlert) {
          const { date } = res.firstAlert;
          alertForm.get('date')?.patchValue([new Date(date), new Date()]);
        } else {
          alertForm.get('date')?.patchValue([new Date(), new Date()]);
        }
      },
        error => {
          if (this.notificationService.templateRef) {
            this.errorsHandlingService.handleError(error);
          }
        });
    }


    forkJoin({
      revenue: this.dataManagerService.getRevenue(),
      partnerQpsStatistic: this.dataManagerService.getPartnerQpsStatistic()
    }).subscribe({
      next: ({ revenue, partnerQpsStatistic }) => {
        // Process Revenue Data
        this.onProcessPartnerRevenue(revenue.revenue);

        // Process Partner QPS Statistics Data
        this.onProcessPartnerQpsStatistic(partnerQpsStatistic);
      },
      error: (error) => {
        this.errorsHandlingService.handleError(error);
      }
    });

  }

  private onProcessPartnerRevenue(revenue: {
    trafficType: { today: any[]; todayTotals: any; yesterday: any[]; yesterdayTotals: any };
    adFormat: { today: any[]; todayTotals: any; yesterday: any[]; yesterdayTotals: any };
  }): void {
    Object.entries(revenue).forEach(([type, revenueType]) => {
      const { todayTotals, yesterdayTotals } = revenueType;
      // Calculate total profit for today and yesterday
      const todayTotal = todayTotals.dspSpend - todayTotals.sspSpend;
      const yesterdayTotal = yesterdayTotals.dspSpend - yesterdayTotals.sspSpend;

      // Update statistics
      this.statistic[type].today.total = todayTotal;
      this.statistic[type].yesterday.total = yesterdayTotal;

      // Notify profit updates
      this.todayProfit = todayTotal;
      this.yesterdayProfit = yesterdayTotal;

      // Process daily revenue data
      ['today', 'yesterday'].forEach(day => {
        const dayData = this.statistic[type][day];
        if (!dayData) return;

        revenueType[day]?.forEach(entry => {
          const typeValue = entry[type];
          if (dayData[typeValue]) {
            const revenue = entry.dspSpend - entry.sspSpend;
            dayData[typeValue].revenue = revenue;
            dayData[typeValue].percent = (revenue * 100) / dayData.total;
          }
        });
      });
    });

    const todayTotalSSPSpend = revenue.trafficType.todayTotals.sspSpend;
    const yesterdayTotalSSPSpend = revenue.trafficType.yesterdayTotals.sspSpend;
    const todayTotalDSPSpend = revenue.trafficType.todayTotals.dspSpend;
    const yesterdayTotalDSPSpend = revenue.trafficType.yesterdayTotals.dspSpend;

    this.profitArray = [
      { name: 'Today', value: this.todayProfit },
      { name: 'Yesterday', value: this.yesterdayProfit },
    ];

    this.sspSpendArray = [
      { name: 'Today', value: todayTotalSSPSpend },
      { name: 'Yesterday', value: yesterdayTotalSSPSpend },
    ];

    this.dspSpendArray = [
      { name: 'Today', value: todayTotalDSPSpend },
      { name: 'Yesterday', value: yesterdayTotalDSPSpend },
    ];

    this.profit$.next(this.profitArray);
    this.sspSpend$.next(this.sspSpendArray);
    this.dspSpend$.next(this.dspSpendArray);

    // Extract statistics for adFormat and trafficType
    this.adFormatItems = this.extractStatItems(this.statistic.adFormat.today);
    this.trafficTypeItems = this.extractStatItems(this.statistic.trafficType.today);

    const nameMappingForTrafficTypes = {
      web: 'Desktop',
      mobile: 'Mobile App',
      mobileWeb: 'Mobile Web',
      ctv: 'CTV'
    };

    const nameMappingForAdFormats = {
      banner: 'Banner',
      video: 'Video',
      native: 'Native',
      pop: 'Pop',
      push: 'Push',
      audio: 'Audio',
      rewarded: 'Rewarded Video'
    };

    this.trafficTypeItems = this.updateNames(this.trafficTypeItems, nameMappingForTrafficTypes);
    this.adFormatItems = this.updateNames(this.adFormatItems, nameMappingForAdFormats);

    this.adFormatItems$.next(this.adFormatItems);
    this.trafficTypeItems$.next(this.trafficTypeItems);
  }

  /**
   * Helper method to extract statistic items excluding "total" and "unknown"
   */
  private extractStatItems(data: Record<string, { revenue: number; percent: number } | number>): PartnerInterfaceGlobal.IStatAdFormatItem[] {
    return Object.entries(data)
      .filter(([key]) => key !== 'total' && key !== 'unknown')
      // .filter(([_, value]) => (value as { revenue: number; percent: number }).revenue !== 0)
      .map(([name, value]) => ({
        name,
        revenue: (value as { revenue: number; percent: number }).revenue,
        percent: (value as { revenue: number; percent: number }).percent,
      }));
  }

  private updateNames(
    data: PartnerInterfaceGlobal.IStatAdFormatItem[],
    nameMapping: { [key: string]: string }
  ): PartnerInterfaceGlobal.IStatAdFormatItem[] {
    return data.map(item => ({
      ...item,
      name: nameMapping[item.name] || item.name // Update name if it matches the mapping
    }));
  }


  private onProcessPartnerQpsStatistic(partnerQpsStatistic: {
    partperQps: {
      incomingReal: number;
      outgoingReal: number;
      incomingStandart: number;
      outgoingStandart: number;
    };
    sspQps: any[];
    dspQps: any[];
  }): void {
    this.regionItems = [];
    Object.values(partnerQpsStatistic).forEach((item: any) => {
      this.regionItems.push({
        name: item.region,
        partnerQps: item.partperQps,
      });
    });

    this.regionItems$.next(this.regionItems);
  }
}
