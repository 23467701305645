import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../../services';

export const ScheduledReportGuard: CanActivateFn = () => {
  const auth = inject(AuthService);
  const router = inject(Router);

  const userRole = auth.userRole;
  const allowedRoles = ['support', 'manager', 'viewer'];
  if (!allowedRoles.includes(userRole)) {
    void router.navigate(['ssp-dsp-dashboard']);

    return false;
  }

  if (auth.partner.partner_plan !== 1 || !auth.partner.is_scheduled_reports) {
    void router.navigate(['ssp-dsp-dashboard']);

    return false;
  }

  return true;
};
