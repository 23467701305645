import { Component, Input } from '@angular/core';

import { PartnerInterfaceGlobal } from '../../../core/models';
@Component({
  selector: 'app-stat-header',
  templateUrl: './stat-header.component.html',
  styleUrls: ['./stat-header.component.scss'],
})
export class StatHeaderComponent {

  @Input() set regionItems(value: PartnerInterfaceGlobal.IStatRegionItem[]) {
    this._regionItems = value;
  }

  @Input() set adFormatItems(value: PartnerInterfaceGlobal.IStatAdFormatItem[]) {
    this._adFormatItems = value;
  }

  @Input() set trafficTypeItems(value: PartnerInterfaceGlobal.IStatTrafficTypeItem[]) {
    this._trafficTypeItems = value;
  }

  get regionItems(): PartnerInterfaceGlobal.IStatRegionItem[] {
    return this._regionItems;
  }

  get adFormatItems(): PartnerInterfaceGlobal.IStatAdFormatItem[] {
    return this._adFormatItems;
  }

  get trafficTypeItems(): PartnerInterfaceGlobal.IStatTrafficTypeItem[] {
    return this._trafficTypeItems;
  }

  protected tabIndex = 0;

  protected tabItems = [
    {
      name: 'Regions',
      icon: 'ng-zorro:regions',
      id: 0,
    },
    {
      name: 'Traffic Type',
      icon: 'ng-zorro:trafficType',
      id: 1,
    },
    {
      name: 'Ad Formats',
      icon: 'ng-zorro:adFormats',
      id: 2,
    }
  ];

  private _regionItems: PartnerInterfaceGlobal.IStatRegionItem[] = [];
  private _adFormatItems: PartnerInterfaceGlobal.IStatAdFormatItem[] = [];
  private _trafficTypeItems: PartnerInterfaceGlobal.IStatTrafficTypeItem[] = [];

  constructor() { }


  onChangeTab(id: number): void {
    this.tabIndex = id;
  }
}
