<div class="suggest-feature-container">
  <div class="suggest-feature-content">
    <form
      nz-form
      nzLayout="vertical"
      class="suggest-feature-form"
      [formGroup]="askSupportForm"
    >
      <div class="form-item-suggest">
        <nz-form-item>
          <nz-form-label class="label-text" nzFor="title">
            Subject
            <span
              nz-icon
              class="tooltip-icon"
              nzType="ng-zorro:infoTooltip"
              nz-tooltip
              nzTooltipOverlayClassName="item-tooltip"
              nzTooltipTitle="Provide a concise subject of your issue"
              nzTooltipPlacement="top"
            ></span>
          </nz-form-label>
          <nz-form-control [nzValidateStatus]="titleValidStatus">
            <input
              nz-input
              class="form-control"
              formControlName="title"
              name="title"
              id="title"
              placeholder="Provide a detailed description of your issue"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="form-item-suggest">
        <nz-form-item>
          <nz-form-label class="label-text" nzFor="description">
            Description
            <span
              nz-icon
              class="tooltip-icon"
              nzType="ng-zorro:infoTooltip"
              nz-tooltip
              nzTooltipOverlayClassName="item-tooltip"
              nzTooltipTitle="Please add the detailed description. In case you report bug / issue in any part of the platform functionality, please mention all the steps you do in more details"
              nzTooltipPlacement="top"
            ></span>
          </nz-form-label>
          <nz-form-control [nzValidateStatus]="descriptionValidStatus">
            <textarea
              nz-input
              [nzAutosize]="{ minRows: 2, maxRows: 10 }"
              class="form-control"
              name="description"
              id="description"
              formControlName="description"
              placeholder="Provide a detailed description of your issue"
              rows="10"
              required
            ></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="form-item-suggest">
        <nz-form-item>
          <nz-form-label nzFor="priority">
            Priority
            <span
              nz-icon
              class="tooltip-icon"
              nzType="ng-zorro:infoTooltip"
              nz-tooltip
              nzTooltipOverlayClassName="item-tooltip"
              nzTooltipTitle="Important for prioritizing support efforts"
              nzTooltipPlacement="top"
            ></span>
          </nz-form-label>
          <nz-form-control [nzValidateStatus]="priorityValidStatus">
            <nz-select
              id="priority"
              name="priority"
              nzShowSearch
              nzPlaceHolder="Choose the priority"
              formControlName="priority"
            >
              <nz-option
                *ngFor="let item of priorityOptions"
                [nzValue]="item.id"
                [nzLabel]="item.name"
                nzCustomContent
              >
                <span>
                  <span class="item-name">{{ item.name }}</span>
                  <span
                    nz-icon
                    nzType="ng-zorro:infoTooltip"
                    nz-tooltip
                    nzTooltipOverlayClassName="item-tooltip"
                    [nzTooltipTitle]="item.tooltip"
                    nzTooltipPlacement="top"
                  ></span>
                </span>
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="form-group-content">
        <nz-upload
          [nzAction]="askSupportService.getImagesUrl"
          nzListType="picture"
          class="upload-list-inline"
          [(nzFileList)]="fileList"
          [nzBeforeUpload]="beforeUpload"
          [nzPreview]="handlePreview"
          [nzMultiple]="true"
          [nzDisabled]="isDisabledButton"
          [nzLimit]="5"
          (nzChange)="handleUpload($event)"
          [nzRemove]="handleRemove"
          [nzShowUploadList]="{
            showDownloadIcon: false,
            showRemoveIcon: true,
            showPreviewIcon: true
          }"
        >
          <button
            nz-button
            nzType="text"
            class="export"
            [disabled]="isDisabledButton"
          >
            Import
            <span nz-icon nzType="upload"></span>
          </button>
        </nz-upload>
        <nz-modal
          [nzVisible]="previewVisible"
          [nzContent]="modalContent"
          [nzFooter]="null"
          (nzOnCancel)="previewVisible = false"
          [nzClosable]="false"
          [nzCentered]="true"
          [nzStyle]="{
          width: '430px',
          height: '430px',
        }"
          [nzBodyStyle]="{ width: '100%', height: '98%' }"
        >
          <ng-template #modalContent>
            <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
          </ng-template>
        </nz-modal>
      </div>
      <div class="form-group-suggest form-bottom__buttons">
        <button
          class="btn-cancel"
          type="button"
          (click)="onCancel()"
          nz-button
          nzType="default"
        >
          Cancel
        </button>
        <button
          class="btn-save"
          type="submit"
          [disabled]="askSupportForm.invalid"
          nz-button
          nzType="primary"
          (click)="onFormSubmit()"
        >
          Send
        </button>
      </div>
    </form>
  </div>
</div>
