import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { UserRole } from '../../enum';
import { AuthService } from '../../services';

export const ExternalUserGuard: CanActivateFn = () => {
  const auth = inject(AuthService);
  const router = inject(Router);

  if (auth.userRole === UserRole.external) {
    router.navigate(['statistics/reports']);
    return false;
  }

  return true;
};
