import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./shared/layouts/public-layout/public-layout.module').then(m => m.PublicLayoutModule),
  },
  {
    path: '',
    loadChildren: () => import('./shared/layouts/secure-layout/secure-layout.module').then(m => m.SecureLayoutModule),
  },
  {
    path: '',
    loadChildren: () => import('./shared/layouts/shared-layout/shared-layout.module').then(m => m.SharedLayoutModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
