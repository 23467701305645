import {
  Component,
  ViewChild,
  AfterViewInit,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@app/core/services';

enum NotificationColors {
  success = '#00a79d',
  error = '#ff4d4f',
  info = '#002199',
}

enum IconTypes {
  success = 'check-circle',
  error = 'close-circle',
  info = 'info-circle',
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements AfterViewInit {
  @ViewChild('template', { static: true }) modalTemplate: any;

  public templateRef: TemplateRef<any>;
  public data: {
    iconType: string;
    color: string;
    notificationType: string;
    leftPartOfText?: string;
    description: string;
    rightPartOfText?: string;
    linkUrl?: string;
  } = {
      iconType: '',
      color: '',
      notificationType: '',
      leftPartOfText: '',
      description: '',
      rightPartOfText: '',
      linkUrl: '',
    };

  protected notificationColors = NotificationColors;
  protected iconTypes = IconTypes;


  constructor(
    private notificationService: NotificationService,
    private router: Router,
  ) { }

  public ngAfterViewInit() {
    this.notificationService.setTemplate(this.modalTemplate);
  }

  protected openLinkByUrl(url: string): void {
    // eslint-disable-next-line max-len
    window.open(url, '_blank');
  }

  protected goToSettings(): void {
    this.router.navigate(['settings']);
  }

}
