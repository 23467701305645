import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PartnerInterfaceGlobal } from '../../models';
import { AuthService, FillStateService } from '../../services';
import { UserRole } from '../../enum';

interface MenuItem {
  icon: string;
  label: string;
  children?: MenuItem[];
  isOpen?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  routeUr?: string;
  isShow?: boolean;
  tooltip: string;
  isDisabled?: boolean;
}

@Injectable()
export class AppConfig {
  public partnerInfo$ = new BehaviorSubject<PartnerInterfaceGlobal.IPartnerGeneral | null>(null);
  public contentHeight$ = new BehaviorSubject<string>('1000px');

  public isCollapsed$ = new BehaviorSubject<boolean>(false);

  public menuItems: MenuItem[] = [
    {
      icon: 'ng-zorro:platformSettings',
      label: 'General Settings',
      isShow: true,
      routeUr: '/settings',
      tooltip: '',
    },
    {
      icon: 'ng-zorro:frameworks',
      label: 'Policies & Frameworks',
      isShow: true,
      isDisabled: false,
      routeUr: '/frameworks',
      tooltip: '',
    },
    {
      icon: 'ng-zorro:integrations',
      label: 'Integrations',
      isShow: true,
      isDisabled: false,
      routeUr: '/integrations',
      tooltip: '',
    },
    {
      icon: 'ng-zorro:trafficAnalyzer',
      label: 'Traffic Analyzer',
      isShow: true,
      isDisabled: false,
      isOpen: false,
      tooltip: '',
      children: [
        { icon: '', label: 'Dashboard', routeUr: '/traffic-analyzer/dashboard', tooltip: '' },
        { icon: '', label: 'Rule Manager', routeUr: '/traffic-analyzer/rule-manager', tooltip: '' },
      ]
    },
    {
      icon: 'ng-zorro:scanners',
      label: 'Scanners',
      isShow: true,
      isDisabled: false,
      routeUr: '/scanners',
      tooltip: '',
    },
    {
      icon: 'ng-zorro:companies',
      label: 'Companies',
      isShow: true,
      isDisabled: false,
      routeUr: '/companies',
      tooltip: '',
    },
    {
      icon: 'ng-zorro:mismatches',
      label: 'Mismatches',
      isShow: true,
      isLast: true,
      isDisabled: false,
      routeUr: '/mismatches',
      tooltip: '',
    },
    {
      icon: 'ng-zorro:account',
      label: 'User Management',
      isShow: true,
      isDisabled: false,
      routeUr: '/users',
      tooltip: '',
    },
    {
      icon: 'ng-zorro:contactUsSidebar',
      label: 'Contact Us',
      isShow: true,
      isDisabled: false,
      routeUr: '',
      tooltip: '',
    },
    {
      icon: 'ng-zorro:knowledgeBase',
      label: 'Knowledge Base',
      isShow: true,
      isDisabled: false,
      tooltip: 'Access the Knowledge Base for detailed guides and FAQs',
    },
    {
      icon: 'ng-zorro:notifications',
      label: 'Alerts',
      isShow: true,
      isDisabled: false,
      tooltip: '',
    },
    {
      icon: 'ng-zorro:logOut',
      label: 'Logout',
      isShow: true,
      isDisabled: false,
      tooltip: '',
    },
  ];

  public menuItems$ = new BehaviorSubject<MenuItem[]>(this.menuItems);

  constructor(
    private authService: AuthService,
    private fillStateService: FillStateService,
  ) { }

  async init(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (this.authService.isAuthenticated()) {
        if (this.authService.userRole !== UserRole.external) {
          this.fillStateService.handle();
          try {
            const res = await this.authService.check().toPromise();
            this.partnerInfo$.next(res.data);
            this.authService.partner = res.data;

            if (res.data.id === 300 && this.authService.userRole === UserRole.support && res.data.partner_domain === 'new.smart-hub.io') {
              const newItem = {
                icon: 'ng-zorro:admin',
                label: 'Admin Settings',
                isShow: true,
                isLast: true,
                isDisabled: false,
                tooltip: '',
                children: [
                  { icon: '', label: 'General', routeUr: '/general', tooltip: '' },
                  { icon: '', label: 'Modules', routeUr: '/modules', tooltip: '' },
                ]
              };
              const mismatchIndex = this.menuItems.findIndex((item) => item.label === 'Mismatches');
              this.menuItems.splice(mismatchIndex + 1, 0, newItem);
            }

            const trafficAnalyzerItem = this.menuItems.find((item) => item.label === 'Traffic Analyzer');
            const alertItem = this.menuItems.find((item) => item.label === 'Alerts');

            if (this.partnerInfo$.value?.partner_plan !== 1) {
              if (trafficAnalyzerItem) {
                trafficAnalyzerItem.tooltip = `Traffic Analyzer is available on Enterprise version.
                If you want to upgrade your platform, please contact our Business Development Manager`;
              }
            }

            if (this.partnerInfo$.value?.partner_plan === 3) {
              if (alertItem) {
                alertItem.tooltip = `Alerts are available on Plus and Enterprise versions.
                If you want to upgrade your platform, please contact our Business Development Manager`;
              }
            }

            this.menuItems$.next(this.menuItems);

          } catch (error) { }
        } else {
          this.menuItems = this.menuItems.filter((item) => item.label === 'Logout');

          this.menuItems$.next(this.menuItems);
        }
      }

      setTimeout(() => resolve(), 100);
    });
  }
}
