import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { PartnerInterfaceGlobal } from '../../../core/models';

@Component({
  selector: 'app-partner-plan-switch',
  templateUrl: './partner-plan-switch.component.html',
  styleUrls: ['./partner-plan-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerPlanSwitchComponent implements OnInit, OnChanges {
  @Input() public set isEditingRow(value: boolean) {
    this._isEditingRow = value;
  }

  public get isEditingRow(): boolean {
    return this._isEditingRow;
  }

  @Input() public set partnerPlan(value: number) {
    this._partnerPlan = value;
  }

  public get partnerPlan(): number {
    return this._partnerPlan;
  }

  @Input() public set planItems(value: PartnerInterfaceGlobal.IPlanItem[]) {
    this._planItems = value;
  }

  public get planItems(): PartnerInterfaceGlobal.IPlanItem[] {
    return this._planItems;
  }

  @Output() public changePartnerPlanEmit = new EventEmitter<{ planValue: boolean; planId: number }>();

  @Input() public set planItemControls(value: {
    basic: boolean;
    plus: boolean;
    enterprise: boolean;
  }) {
    this._planItemControls = value;
  }

  public get planItemControls(): {
    basic: boolean;
    plus: boolean;
    enterprise: boolean;
  } {
    return this._planItemControls;
  }

  private _partnerPlan = 3;
  private _isEditingRow = false;
  private _planItems: PartnerInterfaceGlobal.IPlanItem[] = [];
  private _planItemControls = {
    basic: false,
    plus: false,
    enterprise: false,
  };

  constructor() { }

  ngOnChanges(_: SimpleChanges): void {
    this.onSetPartnerPlanModel();
  }

  public ngOnInit(): void {
    this.onSetPartnerPlanModel();
  }


  protected onChangePartnerPlan(value: boolean, id: number): void {
    if (value) {
      this.planItems.forEach(item => {
        item.disabled = item.id !== id;
        this.partnerPlan = id;
      });

    } else {
      this.planItems = this.planItems.map(item => {
        item.disabled = false;
        this.partnerPlan = 0;
        return item;
      });
    }

    this.changePartnerPlanEmit.emit({ planValue: value, planId: id });
  }

  private onSetPartnerPlanModel(): void {
    this.planItems = this.planItems.map(item => {
      if (item.id === this.partnerPlan && this.isEditingRow) {
        item.disabled = false;
      } else {
        item.disabled = true;
      }
      return item;
    });
  }
}
