import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable, throwError as _throw } from 'rxjs';

import { NotificationComponent } from '@shared/components';

import { AppOptionsService } from '../app-options';
import {
  User,
  CheckDomainResponse,
  Partner,
} from '../../interfaces';
import { UserRole } from '../../enum';
import { NotificationService } from '../notification';

export const REMEMBER_STORAGE_NAME = 'app-remember-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiURl;

  constructor(
    private http: HttpClient,
    private options: AppOptionsService,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
  ) {
    this.apiURl = options.getApiUrl();
  }

  isAuthenticated(): boolean {
    let token = sessionStorage.getItem('token');
    let expiredDate = sessionStorage.getItem('expiredDate');

    if (!expiredDate) {
      expiredDate = localStorage.getItem('expiredDate');
    }

    if (!token) {
      token = localStorage.getItem('token');
    }

    if (!token) {
      return false;
    }

    const isTokenExpired = this.isExpired(expiredDate);

    if (!isTokenExpired) {
      sessionStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
      this.showNotification(
        'error',
        'error',
        'error',
        'Your authentication token has been expired. Please log in again to continue using the platform',
        null
      );
    }
    // Check whether the token is expired and return
    // true or false
    return !isTokenExpired;
  }

  checkLimitQPSForPartner(): Observable<any> {
    return this.http.get(`${this.apiURl}/checklimitqps`).pipe();
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
  }

  checkDomain(): Promise<CheckDomainResponse> {
    return new Promise(
      (resolve, reject) => {
        this.http.get(`${this.apiURl}/check`).subscribe((r: any) => {
          if (!r.success) {
            reject();
            return;
          }
          this.partner = r.data;
          resolve(r);
        }, e => reject(e));
      });
  }

  getTechnicalWorksInfo(): Observable<any> {
    return this.http.get('/check-technical-works');
  }

  getPartnerPublicInfo(): Observable<{
    success: string; data: {
      isMaintenanceMode: boolean;
      name: string;
      title: string
      useTitle: number;
      loginLogo: string;
      headerLogo: string;
      favicon: string;
    }
  }> {
    return this.http.get<{
      success: string; data: {
        name: string;
        title: string
        useTitle: number;
        loginLogo: string;
        headerLogo: string;
        favicon: string;
        isMaintenanceMode: boolean;
      }
    }>(`${this.apiURl}/partner/public-info`).pipe(catchError(this.processError));
  }

  check(): Observable<CheckDomainResponse> {
    return this.http.get<CheckDomainResponse>(`${this.apiURl}/check`)
      .pipe(catchError(this.processError));
  }

  get expiredDate(): string {
    let expiredDate = sessionStorage.getItem('expiredDate');

    if (!expiredDate) {
      expiredDate = localStorage.getItem('expiredDate');
    }

    return expiredDate;
  }

  set expiredDate(value: string) {
    sessionStorage.setItem('expiredDate', value);
    localStorage.setItem('expiredDate', value);
  }


  set token(token: string) {
    localStorage.setItem('token', token);
    sessionStorage.setItem('token', token);
  }

  get token(): string {
    let token = sessionStorage.getItem('token');

    if (!token) {
      token = localStorage.getItem('token');
    }

    if (!token) {
      return null as any;
    }

    return token;
  }

  set userRole(value: string) {
    localStorage.setItem('userRole', value);
    sessionStorage.setItem('userRole', value);
  }

  get userRole(): UserRole {
    let userRole = sessionStorage.getItem('userRole') as UserRole;

    if (!userRole) {
      userRole = localStorage.getItem('userRole') as UserRole;
    }

    const role = userRole;

    return role;
  }

  get mainSspDspTableOpened() {
    return { supply: true, demand: true };
  }

  set mainSspDspTableOpened(value) {
    localStorage.setItem('main-ssp-dsp-table-opened', JSON.stringify(value));
  }

  get partner(): Partner {
    const partner = JSON.parse(localStorage.getItem('partner'));
    if (!partner) {
      return new Partner;
    }
    return partner;
  }

  set partner(partner: Partner) {
    localStorage.setItem('partner', JSON.stringify(partner));
  }

  getUsersPermissionsList(): { id: string, name: string }[] {
    return [
      { id: 'viewer', name: 'View' },
      { id: 'manager', name: 'Edit' },
      { id: 'external', name: 'View Statistics' },
    ];
  }

  createUser(data: User): Observable<{ status: string; data: User }> {
    return this.http.post<{ status: string; data: User }>(`${this.options.getApiUrl()}/user`, JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  editUser(data: User): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/user/role/${data.id}`, JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  getUserByActivationKey(key: string): Observable<any> {
    return this.http.get(`${this.apiURl}/user/activate/${key}`)
      .pipe(catchError(this.processError));
  }

  setUserPassword(password: string, confirm: string, token: string) {
    return this.http.patch(
      `${this.apiURl}/user/password/${token}`,
      { password: password, password_confirmation: confirm },
    ).pipe(catchError(this.processError));
  }

  reset(email: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.post(
        `${this.apiURl}/user/reset`,
        { email: email },
      ).subscribe(
        () => {
          this.showNotification('success', 'success', 'success', 'The password recovery link was sent to your email address.', null);
          resolve(email);
        }, error => {
          reject(error);
        });
    });

  }

  canEditPreset() {
    const role = this.userRole;

    return role === 'support' || role == 'manager';
  }

  hasExternalRole() {
    return this.userRole === 'external';
  }

  isExpired(dateExpired: string): boolean {
    // Parse the expiration date string to a Date object
    const parsedExpireDate = new Date(dateExpired);

    // Get the current date and time
    const currentDate = new Date();

    // Compare the current date and time with the expiration date and time
    const isExpired = currentDate > parsedExpireDate;

    return isExpired;
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
    width: string,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data, nzStyle: { width: width ?? '385px' } });
  }


  public processError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return _throw({ code: 0, messages: ['Application crashed. Please try to refresh the page.'] });
    }
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,

    if (error.status === 400) {
      const messages = [];
      if (error.error.errors) {
        for (const i in error.error.errors) {
          messages.push(error.error.errors[i]);
        }
      }
      return _throw({ code: 400, messages });
    }

    if (error.status === 401) {
      if (error.error.hasOwnProperty('has_access_limited')) {
        return _throw({ code: 401, data: error.error });
      } else {
        return _throw({ code: 401, messages: [error.error.error] });
      }
    }

    if (error.status === 403) {
      return _throw({ code: 403, messages: [error.error.error] });
    }

    if (error.status === 404) {
      return _throw({
        code: 404, messages: error.error.error === 'Partner not found'
          ? [error.error.error]
          : ['Can\'t process the request. Requested URL not found.']
      });
    }

    if (error.status === 422) {
      const errors = error.error.errors;
      const messages = errors ? Object.values(errors).map((item) => {
        if (typeof item === 'object') {
          const errorsArray = Object.values(item).flat().join('\r\n');
          return errorsArray;
        } else {
          return item;
        }
      }) : [];
      return _throw({ code: 422, messages, errors });
    }

    if (error.status === 500) {
      return _throw({ code: 500, messages: ['Internal server error'] });
    }

    if (error.status === 503) {
      return _throw({ code: 503, messages: ['Service Unavailable'] });
    }

    return _throw({ code: 0, messages: ['An error occurred. Please try again later.'] });
  }
}
