import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { StatProfitComponent } from './stat-profit.component';
@NgModule({
  declarations: [StatProfitComponent],
  imports: [
    CommonModule,
    NzTabsModule,
    NzIconModule,
  ],
  exports: [StatProfitComponent]
})
export class StatProfitModule { }
