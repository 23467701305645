<header>
  <div class="header__row">
    <div class="header__column column-icon">
      <div class="column-icon__row">
        <img [src]="partner.headerLogo" [alt]="partner.name" class="logo" />
      </div>
    </div>
    <div class="header__column column-content">
      <div
        class="column-content__items"
        *ngIf="userRole !== userRoleEnum.external"
      >
        <div class="column-content__item">
          <div class="column-content__title">Dashboard</div>
          <div class="column-content__icon">
            <span nz-icon nzType="ng-zorro:dropdown"></span>
          </div>
          <div class="dropdown-menu">
            <a nz-button nzType="text" routerLink="/ssp-dsp-dashboard">
              SSP + DSP
            </a>
            <!-- <button
              nz-button
              nzType="text"
              [disabled]="true"
              nz-tooltip
              nzTooltipOverlayClassName="item-tooltip"
              nzTooltipPlacement="top"
            >
              Publisher + DSP PMP
            </button> -->
            <a
              class="last"
              nz-button
              nzType="text"
              [disabled]="true"
              [class.disabled]="true"
            >
              Edit Dashboard
            </a>
            <div class="soon">Coming Soon</div>
          </div>
        </div>
        <div class="column-content__item">
          <div class="column-content__title">Statistics</div>
          <div class="column-content__icon">
            <span nz-icon nzType="ng-zorro:dropdown"></span>
          </div>
          <div class="dropdown-menu">
            <a routerLink="statistics/reports">Statistics</a>
            <a routerLink="dropped-requests">Dropped Req/Res Report</a>
            <a routerLink="statistics/scanner-statistic">Scanners Statistics</a>
            <a
              nz-button
              nzType="link"
              [disabled]="
                partner.partner_plan !== 1 || !partner.is_scheduled_reports
              "
              nz-tooltip
              nzTooltipOverlayClassName="item-tooltip"
              [nzTooltipTitle]="
                restrictionTooltipForNonEnterpriseMessage('Scheduled reports')
              "
              nzTooltipPlacement="bottom"
              routerLink="statistics/scheduled-reports"
              [class.disabled]="
                partner.partner_plan !== 1 || !partner.is_scheduled_reports
              "
            >
              Scheduled Reports
            </a>
          </div>
        </div>
        <div class="column-content__item">
          <a
            nz-button
            nzType="link"
            class="column-content__title"
            nz-tooltip
            nzTooltipOverlayClassName="item-tooltip"
            [nzTooltipTitle]="restrictionTooltipMessage('Analytics Dashboards')"
            nzTooltipPlacement="bottom"
            [disabled]="partner.partner_plan === 3"
            [class.disabled]="partner.partner_plan === 3"
            routerLink="dashboards"
          >
            Analytics Dashboard
          </a>
        </div>
        <div class="column-content__item">
          <a
            nz-button
            nzType="link"
            class="column-content__title"
            routerLink="billing"
            >Billing</a
          >
        </div>
        <div class="column-content__item">
          <a
            class="column-content__title"
            nz-button
            nzType="link"
            routerLink="list-manager"
          >
            List Manager
          </a>
        </div>
        <div class="column-content__item">
          <div class="column-content__title">Sellers.json</div>
          <div class="column-content__icon">
            <span nz-icon nzType="ng-zorro:dropdown"></span>
          </div>
          <div class="dropdown-menu">
            <a nz-button nzType="link" routerLink="sellers-json"> Dashboard </a>
            <a nz-button nzType="link" routerLink="sellers-json/settings">
              Settings
            </a>
          </div>
        </div>
        <div
          class="column-content__item column-content__item--crawlers"
          nz-tooltip
          nzTooltipOverlayClassName="item-tooltip"
          [nzTooltipTitle]="
            restrictionTooltipForNonEnterpriseMessage('Crawlers')
          "
          nzTooltipPlacement="bottom"
        >
          <button
            nz-button
            nzType="text"
            class="column-content__title"
            [disabled]="true"
            class="column-content__title"
          >
            Crawlers
          </button>
          <div class="column-content__icon">
            <span nz-icon nzType="ng-zorro:dropdown"></span>
          </div>
          <div class="dropdown-menu" *ngIf="false">
            <a>Ads.txt crawler</a>
            <a>Seller.json crawler</a>
            <a>Statistics</a>
          </div>
          <div class="crawler-soon">Coming Soon</div>
          <!-- ! TODO will be implement after discussion with designer <div class="soon">Coming Soon</div> -->
        </div>

        <div class="column-content__item">
          <div class="column-content__title">Loggers</div>
          <div class="column-content__icon">
            <span nz-icon nzType="ng-zorro:dropdown"></span>
          </div>
          <div class="dropdown-menu">
            <a nz-button nzType="link" routerLink="traffic-logger"
              >Incoming Traffic Logger</a
            >
            <a nz-button nzType="link" routerLink="samples-logger"
              >Req/Res Samples Logger</a
            >
          </div>
        </div>
      </div>
      <div
        class="column-content__items"
        *ngIf="userRole === userRoleEnum.external"
      >
        <div class="column-content__item">
          <div class="column-content__title">Statistics</div>
          <div class="column-content__icon">
            <span nz-icon nzType="ng-zorro:dropdown"></span>
          </div>
          <div class="dropdown-menu">
            <a nz-button nzType="link" routerLink="statistics/reports"
              >Statistics</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
