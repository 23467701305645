export interface TMTSettings {
  partnerId: number;
  apiKey: string;
  dailyLimit: number;
  oneTagLimit: number;
  removeTagTime: number;
  personalApiEndpoint: string;
}

export interface BotmanSettings {
  partner_id: number;
  enable_prebid: string;
  enable_postbid: number;
  prebid_business_id: string;
  prebid_sub_business_id: string;
  postbid_business_id: string;
  postbid_sub_business_id: string;
  prebid_scan_limit: number;
  postbid_scan_limit: number;
}

export interface ForensiqSettings {
  partnerId: number;
  user: string;
  limits: number;
}

export interface ProtectedmediaSettings {
  partnerId: number;
  pmAccountId: string;
  projectId: string;
  privateKeyId: string;
  privateKey: string;
  clientEmail: string;
  clientId: string;
  limitPerDay: number;
}

export interface SellersJsonSettings {
  contact_domain: string;
  contact_email: string;
  contact_address: string;
  contact_phone: string;
  seller_number?: number;
}

export interface GeoedgeSettings {
  header: string;
  footer: string;
}

export interface PixalatePreBidSettings {
  partner_id: number;
  ip: string;
  ifa: string;
  ua: string;
  ott: string;
  def_app: string;
  domain: string;
  hr_app: string;
  dc_subnet: string;
}

export interface RubiconSettings {
  adapter_name: string;
  username: string;
  password: string;
}

export interface PartnerSettings {
  tcf_vendor_id?: number;
  use_tcf_vendor_id: boolean;
  id: number;
  name: string;
  ns: string;
  logo: string;
  useTMT: boolean;
  useForensiq: boolean;
  usePM: boolean;
  connectedPixalatePrebid: boolean;
  connectedPMPrebid: boolean;
  usePixalatePrebid: boolean;
  usePixalate: boolean;
  useSsl: boolean;
  usePMPreBid: boolean;
  useGeoedge: boolean;
  useBotman: boolean;
  usePrefix: boolean | number;
  impressionExpiry: number;
  uuid: string;
  adstxtTagId: string;
  isServiceDomain: boolean;
  tmtSettings: TMTSettings;
  forensiqSettings: ForensiqSettings;
  protectedmediaSettings: ProtectedmediaSettings;
  sellersJson: SellersJsonSettings;
  sellersJson2?: SellersJsonSettings;
  geoedgeSettings: GeoedgeSettings;
  botman_settings: BotmanSettings;
  pixalate_pb_settings: PixalatePreBidSettings;
  pixalateSettings: any;
  smarthub_account: boolean;
  rubicon_settings?: RubiconSettings;
  prebidIntegration?: {
    wl_prebid: number;
    wl_prebid_time: string;
    wl_prebid_js: number;
    js_prebid_time: string;
  };
  domains?: { partnerId: number, region: string, ns: string }[];
  ssp_max_bidfloor?: number,
  dsp_max_bidprice?: number,
  qps_adapter_percentage?: number
  has_rubicon_settings?: boolean;
  has_win_rate_booster?: boolean;
  hide_deleted_endpoints?: boolean;
  custom_api_statistic?: boolean;
  useWhiteops?: number;
}
