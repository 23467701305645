import { Injectable } from '@angular/core';

import { ErrorsHandlingService } from '../errors-handling';

@Injectable({
  providedIn: 'root'
})
export class ViewerRestrictionService {

  constructor(
    private errorsHandlingService: ErrorsHandlingService,
  ) { }


  public restrictUserViewer(): void {
    this.errorsHandlingService.showNotification(
      'error',
      'error',
      'error',
      'Action not allowed: you have view-only permission and cannot make any changes.',
      null
    );
    return;
  }

  public restrictByPartnerPlan(): void {
    this.errorsHandlingService.showNotification(
      'error',
      'error',
      'error',
      `The endpoint is not available for editing because your current plan no longer supports the connection type of this endpoint.
       If you want to upgrade your platform, please contact our Business Development Manager`,
      null
    );
    return;
  }

  public restrictionForNonEnterprisePlan(option: string): void {
    this.errorsHandlingService.showNotification(
      'error',
      'error',
      'error',
      `${option} is available on Enterprise version. 
    If you want to upgrade your platform, please contact our Business Development Manager`,
      '450px'
    );
    return;
  }

  public restrictionForDeletedEndpoint(): void {
    this.errorsHandlingService.showNotification(
      'error',
      'error',
      'error',
      'This endpoint has been deleted. Please, contact your administrator for more details.',
      null
    );
    return;
  }
}
