import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as CryptoJS from 'crypto-js';

import { generateRandomString } from '../../../core/helpers';

import { AuthService } from '../../../core/services';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const secretKey = generateRandomString(32);
    const date = new Date();
    const dateString = this.formatDate(date);
    const timestamp = new Date(dateString).getTime().toString();
    const customKey = this.encrypt(timestamp, secretKey);
    if (this.auth.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.token}`,
          'X-Custom-Client': customKey,
          'X-Custom-Token': secretKey,
        },
      });
    }


    return next.handle(request).pipe(catchError((error) => {
      this.handleAuthError(error);
      return of(error);
    }) as any);
  }

  private handleAuthError(error: HttpErrorResponse) {
    if (error.status === 503) {
      this.router.navigate(['/technical-works']);
    }

    if (error.status === 401) {
      this.router.navigate(['/login']);
    }
    throw error;
  }

  private padZero(num: number): string {
    return num.toString().padStart(2, '0');
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  private encrypt(data: string, secretKey: string): string {
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    const ivString = CryptoJS.enc.Base64.stringify(iv);
    const result = ivString + ':' + encryptedString;
    return result;
  }
}
