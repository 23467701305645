import { ITableItem } from '../table-config';

export interface IAdminsPartnerResponse {
  success: boolean;
  data: {
    current_page: number;
    data: ITableItem[]
    per_page: number;
    total: number;
  }
}

export interface IAdminsPartnerGeneral {
  current_page: number;
  data: ITableItem[]
  per_page: number;
  total: number;
}

export interface ILogResponse {
  UnixDate: number;
  actionType: string;
  createdAt: string;
  entityId: number;
  id: number;
  modelClass: string;
  previous: object;
  updates: object;
  user: {
    active: number;
    createdAt: string;
    deletedAt: unknown;
    email: string;
    id: number;
    isOverLimitQps: number;
    isSupport: number;
    is_hidden: number;
    lastLogin: string;
    name: string;
    partnerId: number;
    remember_token: string;
    reportingForCompanyId: unknown;
    role: string;
    updatedAt: string;
  },
  userId: number;
}

export interface IAdminsActivityLogResponse {
  success: boolean;
  data: {
    request: IActivityLogRequest;
    partners: IActivityLogPartners[],
    models: string[];
    actions: {
      current_page: number;
      data: IAdminTableData[];
      first_page_url: string;
      from: number;
      last_page: number;
      last_page_url: string;
      next_page_url: string;
      path: string;
      per_page: number;
      prev_page_url: string;
      to: number;
      total: number;
    }
  }
}

export interface IAdminPartnerData {
  active: number;
  adstxtTagId: string;
  allow_scalarr_changing: unknown;
  allPartnerUsersIsDisabled: number;
  certificate_expire_at: string;
  clusterId: number;
  company_sync_url: string;
  connectedPMPrebid: number;
  connectedPixalatePrebid: number;
  cookie_storage: string;
  createdAt: string;
  deletedAt: string;
  domains: { partnerId: number, region: string, ns: string }[];
  dspQps: number;
  favicon: string;
  frontendAppDomain: string;
  headerLogo: string;
  id: number;
  impressionExpiry: number;
  incomingQPS: number;
  incomingStandart: number;
  isServiceDomain: boolean;
  is_cookie_sync: boolean;
  jivochatSrcId: unknown;
  loginLogo: string;
  logo: string;
  name: string;
  financial_name: string;
  nodeIpWithExampleBids: string;
  not_paid: number;
  ns: string;
  outgoingQPS: number;
  outgoingStandart: number;
  partnerSmtp: unknown;
  partner_iframe_sync_url: string;
  partner_sync_url: string;
  prefix: string;
  regions_limits: {
    id: number;
    incomingLimit: number;
    outgoingLimit: number;
    partnerId: number;
    region: string;
  }[]
  reportingApiDomain: string;
  revshare: number;
  sspQps: number;
  statServer: number;
  tcf_vendor_id: string;
  title: string;
  updatedAt: string;
  useBotman: number;
  useCookieSync: number;
  useForensiq: number;
  useGeoedge: number;
  usePM: number;
  usePMPreBid: number;
  usePixalate: number;
  usePixalatePrebid: number;
  usePrefix: number;
  useSsl: number;
  useTMT: number;
  useTitle: number;
  useTotalSpendLimit: number;
  use_smarthub_pixalate: number;
  use_tcf_vendor_id: number;
  uuid: string;
  has_automatic_access_limitation: boolean;
  is_traffic_analyzer: number;
  is_slack_alerts: number;
  wl_prebid: number;
  wl_prebid_js: number;
}

interface IAdminUserData {
  active: number;
  createdAt: string;
  deletedAt: string;
  email: string;
  id: number;
  isOverLimitQps: number;
  isSupport: number;
  is_hidden: number;
  lastLogin: string;
  name: string;
  partner: IAdminPartnerData;
  partnerId: number;
  remember_token: string;
  reportingForCompanyId: unknown;
  role: string;
  updatedAt: string;
}

export interface IAdminTableData {
  UnixDate: number;
  actionType: string;
  createdAt: string;
  entityId: number;
  id: number;
  modelClass: string;
  previous: { not_paid: number }
  updates: { not_paid: boolean }
  user: IAdminUserData;
  userId: number;
}

interface IActivityLogPartners {
  domains: { partnerId: number, region: string, ns: string }[];
  length: string;
  id: number;
  incomingStandart: number;
  isServiceDomain: boolean;
  name: string;
  outgoingStandart: string;
  regions_limits: { id: number, partnerId: number, region: string, incomingLimit: number, outgoingLimit: number }[];
}

interface IActivityLogRequest {
  from: string;
  modelClass: string;
  page: string;
  partner: string;
  partnerId: string;
  per_page: string;
  to: string;
  total: string;
}
