<div class="stat-header">
  <nz-tabset (nzSelectedIndexChange)="onChangeTab($event)">
    <nz-tab *ngFor="let tab of tabItems" [nzTitle]="titleTemplate">
      <ng-template #titleTemplate>
        <div class="tab-item" [class.active]="tabIndex === tab.id">
          <span nz-icon [nzType]="tab.icon" style="margin-right: 8px"></span>
          {{ tab.name }}
        </div>
      </ng-template>
    </nz-tab>
  </nz-tabset>
  <div class="region-dashboard" *ngIf="tabIndex === 0">
    <div class="region" *ngFor="let region of regionItems">
      <h4 class="region-name">{{ region.name }}</h4>
      <div class="region-incoming">
        <p class="region-incoming__title">Incoming QPS &nbsp;:&nbsp;</p>
        <p class="region-incoming__value">
          {{ region.partnerQps.incomingReal }} /
          {{ region.partnerQps.incomingStandart }}
        </p>
      </div>
      <div class="region-outgoing">
        <p class="region-outgoing__title">Outgoing QPS &nbsp;:&nbsp;</p>
        <p class="region-outgoing__value">
          {{ region.partnerQps.outgoingReal }} /
          {{ region.partnerQps.outgoingStandart }}
        </p>
      </div>
    </div>
  </div>
  <div class="traffic-dashboard" *ngIf="tabIndex === 1">
    <div class="traffic-body" *ngFor="let trafficType of trafficTypeItems">
      <div class="traffic-content">
        <h4 class="traffic-name">{{ trafficType.name }}</h4>
        <p class="traffic-value">
          ${{ trafficType.revenue | number : "1.2-2" }} /
          {{ trafficType.percent | number : "1.0-0" }}%
        </p>
      </div>
    </div>
  </div>
  <div class="format-dashboard" *ngIf="tabIndex === 2">
    <div class="format-body" *ngFor="let adFormat of adFormatItems">
      <div class="format-content">
        <h4 class="format-name">{{ adFormat.name }}</h4>
        <p class="format-value">
          ${{ adFormat.revenue | number : "1.2-2" }} /
          {{ adFormat.percent | number : "1.0-0" }}%
        </p>
      </div>
    </div>
  </div>
</div>
