export const CardBgColor: { [key: string]: string } = {
  demand_bid_qps: '#1B7E84',
  supply_bid_qps: '#002199',
  incoming_requests: '#002199',
  valid_qps: '#1B7E84', // 86328E
  outgoing_requests: '#86328E',
  today: '#002199',
  yesterday: '#00FFF0',
  two_days_ago: '#54698D',
  ssp_requests: '#002199',
  requests: '#002199',
  responses: '#002199',
  timeout_rate: '#002199',
  total_bid_rate: '#002199',
  bid_rate: '#002199',
  fill_rate: '#002199',
  imps: '#002199',
  profit: '#002199',
  render_rate: '#002199',
  ssp_ecpm: '#002199',
  dsp_ecpm: '#002199',
  ssp_rcpm: '#002199',
  dsp_rcpm: '#002199',
  ssp_win_rate: '#002199',
  dsp_win_rate: '#002199',
  ssp_wins: '#002199',
  wins: '#002199',
};

export const ChartTotalColor: { [key: string]: string } = {
  today: '#002199',
  yesterday: 'rgb(0, 255, 240)',
  two_days_ago: 'rgb(84, 105, 141)'
};

export const CardTextColor = {
  demand_bid_qps: '#fff',
  supply_bid_qps: '#fff',
  valid_qps: '#fff',
  incoming_requests: '#fff',
  outgoing_requests: '#fff',
  today: '#FFF',
  yesterday: '#333',
  two_days_ago: '#D3D3D3',
  ssp_requests: '#fff',
  requests: '#fff',
  responses: '#fff',
  timeout_rate: '#fff',
  total_bid_rate: '#fff',
  bid_rate: '#fff',
  fill_rate: '#fff',
  imps: '#fff',
  profit: '#fff',
  render_rate: '#fff',
  ssp_ecpm: '#fff',
  dsp_ecpm: '#fff',
  ssp_rcpm: '#fff',
  dsp_rcpm: '#fff',
  ssp_win_rate: '#fff',
  dsp_win_rate: '#fff',
  ssp_wins: '#fff',
  wins: '#fff',
};

export const generatedColors = new Set([
  'rgb(0, 255, 240)',
  'rgb(127, 0, 255)',
  'rgb(0, 0, 255)',
  'rgb(128, 0, 0)',
  'rgb(0, 128, 0)',
  'rgb(0, 0, 128)',
  'rgb(255, 128, 0)',
  'rgb(128, 0, 128)',
  'rgb(0, 128, 128)',
  'rgb(128, 128, 255)',
  'rgb(128, 128, 0)',
  'rgb(255, 128, 0)',
  'rgb(0, 64, 64)',
  'rgb(64, 64, 0)',
  'rgb(64, 0, 64)',
]);