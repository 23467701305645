import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerPlanSwitchComponent } from './partner-plan-switch.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    PartnerPlanSwitchComponent
  ],
  imports: [
    CommonModule,
    NzSwitchModule,
    FormsModule,
  ],
  exports: [PartnerPlanSwitchComponent],
})
export class PartnerPlanSwitchModule { }
